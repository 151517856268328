import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { compress, decompress } from 'lz-string';
import { GenericUtils } from '@app/app.commonutils';

/************************************/
/* SYNCHRONIZATION SERVICE          */
/************************************/

export interface StoredLogin {
    deviceid: string;
    session: string;
    username: string;
};

export interface SyncChange {
    entrynfo: any;
    relation: any;
    requires: any;
};

export interface SessionToken {
    session: string;
    placeid: string;
    orderid: string;
};

export interface StoredChanges {
    timestamp: any;
    updates: any;
}

abstract class StoreDatabase {

    constructor(protected store: storeService){
        /* nothing to do */
    }

    /************************************/
    /* COMMON INTERFACE                 */
    /************************************/

    abstract ClearStorage();

    abstract LoadSessionData(deviceid, sessionid);
    abstract SaveSessionData(deviceid, sessionid, changes, timestamp);
    abstract ClearSessionData();
    
    abstract LoadPlaceData(placeid);
    abstract SavePlaceData(placeid, changes, timestamp);
    abstract ClearPlaceData(placeid);

    abstract LogTicketAction(place, ticket);
    abstract GetActionsLog(place, _inidate, _enddate);

    /************************************/
    /* PROTECTED METHODS (CHANGES)      */
    /************************************/

    protected _MergeItem(olditem, newitem){
        if (!olditem){
            return newitem;     // if no previous item, then merge is the new item
        }

        let _merged = olditem;

        for(let _field in newitem){
            if (!(_field in olditem)){
                _merged[_field] = newitem[_field];
                continue;   // not in old item (copied from new)
            }

            if (!(newitem[_field] instanceof Array)){
                _merged[_field] = newitem[_field];
            }
            else {  // concatenate arrays (do not loose old items)
                _merged[_field] = _merged[_field].concat(newitem[_field]);
                _merged[_field] = [...new Set(_merged[_field])];
            }
        }

        return _merged;
    }

    /************************************/
    /* PROTECTED METHODS (LOGGER)       */
    /************************************/

    protected _placeactionsdata = new Map();  // keeps place log in memory
    protected _placeinvoicesmap = new Map();  // map uuid -> invoiceno

    protected _localAction(actions, ticket){
        let _action = null;

        // get the last action for this ticket
        let _last = null;
        for(let _idx = actions.length - 1; (_last == null) && (_idx >= 0); _idx--){
            if ((actions[_idx].series == ticket.series) &&  (actions[_idx].invceno == ticket.invceno)){
                _last = actions[_idx];
            }
        }
        
        // compare last action to current action
        let _new_status = ('status' in ticket) ? ticket['status'] : null;
        let _new_price = ('price' in ticket) ? ticket['price'] : null;
        let _new_payment = ('payment' in ticket) ? ticket['payment'] : null;

        if (_last == null){
            switch(_new_status){
                case 'DE':
                case 'CC':
                    _action = 'CANCELLED';
                    break;
                case 'PD':
                    _action = 'COMPLETED';
                    break;
                default:
                    _action = 'CREATED';
                    break;
            }
        }
        else {
            let _old_status =  ('status' in _last) ? _last['status'] : null;
            let _old_price = ('price' in _last) ? _last['price'] : null;
            let _old_payment = ('payment' in _last) ? _last['payment'] : null;

            if (_old_payment != _new_payment){
                _action = _new_payment;   
            }

            if (_old_price != _new_price){
                _action = (_new_payment != '') ? 'RETURN' : 'MODIFIED';
            }

            if (_old_status != _new_status){
                switch(_new_status){
                    case 'AC':
                        switch(_old_status){
                            case 'PR':
                                _action = 'ACTIVE';
                                break;
                            case 'RD':
                                _action = 'MODIFIED';
                                break;
                            default:
                                _action = 'REOPEN';
                                break;
                        }
                        break;
                    case 'RD':
                        _action = 'READY';
                        break;
                    case 'PP':
                        switch(_new_payment){
                            case 'PAYACCOUNT':
                                _action = 'TOACC';
                                break;
                            default:
                                _action = 'TOPAY';
                                break;
                        }
                        break;
                    case 'PD':
                        _action = 'PAYMENT';
                        break;
                    case 'CC':
                        _action = (_new_payment != '') ? 'REFUND' : 'CANCELLED';
                        break;
                    case 'DE':
                        _action = 'CANCELLED';
                        break;
                }
            }

            if ((_new_status == 'DE') || (_new_price == 0)){
                _action = 'CANCELLED';
            }
        }

        return _action || 'NOCHANGES';
    }

    protected _localDate(_date){
        let dd = ("0" + _date.getUTCDate()).slice(-2);
        let mm = ("0" + (_date.getUTCMonth()+1)).slice(-2); // getMonth() is zero-based
        let yy = _date.getUTCFullYear();
        let hh = ("0" + _date.getUTCHours()).slice(-2);
        let mn = ("0" + _date.getUTCMinutes()).slice(-2);
        let ss = ("0" + _date.getUTCSeconds()).slice(-2);
          
        return yy + "-" + mm + "-" + dd + " " + hh + ":" + mn + ":" + ss;
    }

    protected _logEntry(_action, _timestamp, ticket){
        return {
            // calculated values
            date: _timestamp,
            action: _action,

            // ticket current values
            _uuid: ticket._uuid,
            series: ticket.series,
            invceno: ticket.invceno,
            status: ticket.status,
            price: ticket.price,
            refund: ticket.refund,
            payment: ticket.payment,
            paidby: ticket.paidby ? ticket.paidby.objid : null,
            paidon: ticket.paidon ? this._localDate(ticket.paidon): null,

            // ticket linked objects
            account: ticket.account ? ticket.account.objid : null,
            webpay: ticket.transaction ? ticket.transaction.objid : null,
            session: ticket.session ? ticket.session.objid : null,

            // hash check (always true for local changes)
            hshchk: true
        }
    }

    protected _ticketLog(place, _inidate, _enddate){
        let _header = [ 'action', 'date', 'series', 'invceno', 'status', 'price', 'refund', 'payment', 'webpay', 'account', 'session', 'paidby', 'paidon' ];
        let _reglog = [];

        let _ini_stamp = _inidate.getTime();
        let _end_stamp = _enddate.getTime();

        for(let _entry of this._placeactionsdata[place.objid]){
            let _cur_stamp = (new Date(_entry['date'].replace(' ', 'T') + '.000Z')).getTime();
            if ((_cur_stamp < _ini_stamp) || (_cur_stamp > _end_stamp)){
                continue;   // return only logs in the requested period
            }

            let _row = [];
            for(let _field of _header){
                _row.push((_field in _entry) && (_entry[_field] != null) ? _entry[_field] : '');
            }
            _reglog.push(_row);          
        }

        return {
            header: _header,
            reglog: _reglog
        };
    }
}

/************************************/
/* LOCAL DATABASE (INDEXEDDB)       */
/************************************/

class IndexDatabase extends StoreDatabase {

    private _session_indexeddb = 'upp-stored-ddbb_session';
    private _session_indexeddb_metadata = 'ixdb_metadata';
    private _session_indexeddb_changest = 'ixdb_changest';

    private _place_indexeddb = 'upp-stored-ddbb_place_';
    private _place_indexeddb_metadata = 'ixdb_metadata';
    private _place_indexeddb_changest = 'ixdb_changest';

    private     _logactions_indexeddb = 'upp-stored-actions_';
    private _logactions_indexeddb_actions = 'ixdb_actions';

    constructor(store: storeService){
        super(store);
    }

    private _runinstore(store: IDBObjectStore, action: string, ...args: any[]): Promise<IDBRequest> {
        return new Promise((resolve, reject) => {
            let _action: IDBRequest;

            try {
                _action = store[action](...args);
            } catch (error) {
                return reject(error);
            }
    
            _action.onsuccess = (event: Event) => {
                resolve(_action);
            };
    
            _action.onerror = (event: Event) => {
                console.error("[STORAGE]: Running operation '" + action + "' in indexeddb", (event.target as IDBRequest).error);
                reject(_action);
            };
        });
    }    

    private _SaveChange(store, change){
        let _key = change['table'] + '@' + change['objid'];

        function mysqlToDateStr(date){
            return date ? date.replace(' ', 'T') + '.000Z' : null;
        }    
    
        return new Promise((resolve) => {
            let _checkupdate = new Promise <any> ((resolve) => {
                this._runinstore(store, 'get', _key)
                .then((target) => {
                    let _cache = target.result;
                    if (_cache){
                        let _chgupdate = new Date(mysqlToDateStr(change['updated'])).getTime();
                        let _dtaupdate = new Date(mysqlToDateStr(_cache['updated'])).getTime();
                        
                        let _update = (_chgupdate > _dtaupdate);
                        let _stored = null;
                        if (_update){
                            _stored = this.Decrypt(_cache.change);
                        }

                        resolve({
                            update: _update,
                            stored: _stored
                        });    
                    }
                    else {  // no data: must be written
                        resolve({
                            update: true,
                            stored: null
                        });  
                    }
                })
                .catch(() => {  // error reading: try to overwrite
                    resolve({     
                        update: true,
                        stored: null
                    });
                })    
            });

            _checkupdate.then(
            (updatenfo) => {
                if (updatenfo.update){

                    // merge the current change with the stored instances
                    let _merged = null;
                    if (!('_actn' in change) || (change['_actn'] != 'do_delete')){
                        _merged = this._MergeItem(updatenfo.stored,  change);
                        let _data = {
                            update: change['updated'],
                            change: this.Encrypt(_merged)
                        };
    
                        this._runinstore(store, 'put', _data, _key)
                        .then(() => {
                            resolve(true);      // overwritten
                        })
                        .catch(() => {
                            resolve(false);     // error saving
                        })    
                    }
                    // delete the current change from the stored instances
                    else {  
                        this._runinstore(store, 'delete', _key)
                        .then(() => {
                            resolve(true);      // deleted
                        })
                        .catch(() => {
                            resolve(false);     // error deleting
                        })    
                    }

                }
                else {
                    resolve(true);  // no need to update
                }
            });
        });
    }

    /************************************/
    /* DATA ENCRYPT/DECRYPT             */
    /************************************/

    private Encrypt(data){
        let _data = '';

        let _b64 = GenericUtils.stringToBase64(JSON.stringify(data));
        if (_b64){
            let _pos = [null, null, null];

            for (let _idx = 0; _idx < _pos.length; _idx++) {
                let _candidate = Math.floor(Math.random() * Math.min(_b64.length, 16));
                while (_pos.includes(_candidate)) {
                    _candidate = Math.floor(Math.random() * Math.min(_b64.length, 16));
                }
                _pos[_idx] = _candidate;
            }

            _pos.sort((a, b) => {
                return a - b
            });

            for (let _idx = 0; _idx < _pos.length; _idx++) {
                _data += _pos[_idx].toString(16) + _b64[_pos[_idx]];
            }

            let _offset = 0;
            for (let _idx = 0; _idx < _pos.length; _idx++) {
                _data += _b64.slice(_offset, _pos[_idx]);
                _offset = _pos[_idx] + 1;
            }
            _data += _b64.slice(_offset);
        }

        return _data;
    }

    private Decrypt(data){
        let _data = '';

        let _pos = [ 
            [ parseInt(data[0], 16), data[1] ], 
            [ parseInt(data[2], 16), data[3] ], 
            [ parseInt(data[4], 16), data[5] ]
        ];

        let _b64 = data.slice(6).split('');
        for (let _idx = 0; _idx < _pos.length; _idx++) {
            _b64.splice(_pos[_idx][0], 0, _pos[_idx][1]);
        }
        _data = _b64.join('');

        return JSON.parse(GenericUtils.base64ToString(_data));
    }    

    /************************************/
    /* CLEANUP                          */
    /************************************/

    private _CleanDatabase(dbname, stores){
        return new Promise((resolve) => {
            let _request = indexedDB.open(dbname);
        
            _request.onupgradeneeded = (event) => {
                let _db = (event.target as IDBOpenDBRequest).result;
    
                for(let _store of stores){
                    if (!_db.objectStoreNames.contains(_store)) {
                        _db.createObjectStore(_store);
                    }    
                }
            };

            _request.onsuccess = (event) => {
                let db = (event.target as IDBOpenDBRequest).result;

                let _stores: string[] = [];
                for (let i = 0; i < db.objectStoreNames.length; i++) {
                    _stores.push(db.objectStoreNames[i]);
                }

                if (_stores.length > 0){
                    let transaction = db.transaction(_stores, 'readwrite');
                    transaction.oncomplete = () => {
                        db.close();

                        console.log(`[STORAGE] Database '${dbname}' content cleared successfully.`);
                        resolve(true);
                    };
                    transaction.onerror = (event) => {
                        db.close();

                        console.error(`[STORAGE] Error clearing database '${dbname}':`, (event.target as IDBOpenDBRequest).error);
                        resolve(false);
                    };
            
                    for (let _store of _stores) {
                        transaction.objectStore(_store).clear();
                    }                
                }
                else {
                    resolve(false);  // no stores
                }
            };
    
            _request.onerror = (event) => {
                console.error("[STORAGE] Error opening database '" + dbname + "':", (event.target as IDBOpenDBRequest).error);
                resolve(false);
            };
        }); 
    }

    async ClearStorage(){
        let _databases = await indexedDB.databases();
        for (let _database of _databases){
            if (_database.name == this._session_indexeddb){
                await this._CleanDatabase(_database.name, [
                    this._session_indexeddb_metadata, this._session_indexeddb_changest
                ]);
            }

            if (_database.name.startsWith(this._place_indexeddb)){
                await this._CleanDatabase(_database.name, [
                    this._place_indexeddb_metadata, this._place_indexeddb_changest
                ]);
            }
        }    
    }

    /************************************/
    /* SESSION DATABASE                 */
    /************************************/
    
    async LoadSessionData(deviceid, sessionid){
        return new Promise((resolve) => {
            let _request = this._OpenSessionDatabase();
            _request.onerror = (event) => {
                console.error("[STORAGE] Error opening indexeddb storage: ", _request.error);
                resolve(null);
            }  

            _request.onsuccess = (event) => {
                let _changesdb = (event.target as IDBOpenDBRequest).result;
                this._TestSessionMeta(_changesdb, deviceid, sessionid).then(
                _isvalid => {
                    if (_isvalid){
                        this._LoadSessionData(_changesdb).then(
                        (data) => {
                            if (data){
                                resolve({
                                    updates: data
                                });     
                            }
                            else {
                                resolve(null);
                            }
                        });
                    }
                    else {
                        resolve(null);      // invalid session data
                    }
                });
            }
        });
    }

    async SaveSessionData(deviceid, sessionid, changes, timestamp){
        return new Promise((resolve) => {
            let _request = this._OpenSessionDatabase();
            _request.onerror = (event) => {
                console.error("[STORAGE] Error opening indexeddb storage: ", _request.error);
                resolve(null);
            }  

            _request.onsuccess = (event) => {
                let _changesdb = (event.target as IDBOpenDBRequest).result;
                this._SaveSessionMeta(_changesdb, deviceid, sessionid, timestamp).then(
                _issaved => {
                    if (_issaved){
                        this._SaveSessionData(_changesdb, changes).then(
                        (data) => { 
                            resolve(true) 
                        });
                    }
                    else {
                        resolve(false);      // unsaved session data
                    }
                });
            }
        })
    }

    async ClearSessionData(){
        await this._CleanDatabase(this._session_indexeddb, [
            this._session_indexeddb_metadata, this._session_indexeddb_changest
        ]);
    }    

    /* private methods */

    private _OpenSessionDatabase(){
        let _request = indexedDB.open(this._session_indexeddb);

        _request.onupgradeneeded = (event) => {
            let _db = (event.target as IDBOpenDBRequest).result;

            if (!_db.objectStoreNames.contains(this._session_indexeddb_metadata)) {
                _db.createObjectStore(this._session_indexeddb_metadata);
            }

            if (!_db.objectStoreNames.contains(this._session_indexeddb_changest)) {
                _db.createObjectStore(this._session_indexeddb_changest);
            }
        };

        _request.onblocked = (event) => {
            console.error("[STORAGE] The storage '" + this._session_indexeddb + "' is blocked!!", event)
        }

        return _request;
    }

    private _TestSessionMeta(_changesdb, deviceid, sessionid){
        let _metadata = this._session_indexeddb_metadata;

        return new Promise((resolve) => {
            if (_changesdb && _changesdb.objectStoreNames.contains(_metadata)){
                let _transaction = _changesdb.transaction([_metadata], 'readonly');
                this._runinstore(_transaction.objectStore(_metadata), 'get', 0)
                .then((target) => {
                    let _data = target.result;
                    if (!_data || (_data['deviceid'] != deviceid) || (_data['sessionid'] != sessionid)){
                        resolve(false);  // not for this session
                    }
                    else {
                        let _now = new Date();
                        let _lst = new Date(_data['timestamp'].replace(' ', 'T') + '.000Z');

                        if ((_now.getTime() - _lst.getTime()) < 5 * 24 * 3600 * 1000){
                            resolve(true);  // session data is valid
                        }
                        else {
                            console.warn("[STORAGE] Stored data are outdated!");
                            resolve(false);   // storage data is outdated
                        }
                    }
                })
                .catch(() => {
                    resolve(false);    // no data
                });
            }
            else {
                resolve(false);     // error or storage is unavailable
            }
        });
    }

    private _LoadSessionData(_changesdb){
        let _changest = this._session_indexeddb_changest;
        
        return new Promise((resolve) => {
            if (_changesdb && _changesdb.objectStoreNames.contains(_changest)){
                let _transaction = _changesdb.transaction([_changest], 'readonly');
                this._runinstore(_transaction.objectStore(_changest), 'getAll')
                .then((target) => {
                    let _data = [];

                    let _items = target.result || [];
                    for(let _item of _items){
                        _data.push(this.Decrypt(_item['change']));
                    }    

                    resolve((_data.length == 0) ? null : _data);
                })
                .catch(() => {
                    resolve(null);    // no data
                });
            }
            else {
                resolve(null);      // error or storage is unavailable
            }
        });
    }

    private _SaveSessionMeta(_changesdb, deviceid, sessionid, timestamp){
        let _metadata = this._session_indexeddb_metadata;

        return new Promise((resolve) => {
            let _data = {
                deviceid: deviceid,
                sessionid: sessionid,
                timestamp: timestamp
            };

            try {
                let _transaction = _changesdb.transaction([_metadata], 'readwrite');
                this._runinstore(_transaction.objectStore(_metadata), 'put', _data, 0)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);    // no data
                });    
            }
            catch(error){
                console.warn("[STORAGE] Error in IDBDatabase transaction!");
                resolve(false);
            }
        });
    }

    private _SaveSessionData(_changesdb, changes){
        let _changest = this._session_indexeddb_changest;

        return new Promise((resolve) => {
            if (changes.length > 0){
                try {
                    let _transaction = _changesdb.transaction([_changest], 'readwrite');
                    let _objectStore = _transaction.objectStore(_changest);
        
                    let _changepromises = [];
                    for(let _change of changes){
                        _changepromises.push(this._SaveChange(_objectStore, _change));
                    }
        
                    Promise.all(_changepromises)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(() => {
                        resolve(false);
                    })
                }
                catch(error){
                    console.warn("[STORAGE] Error in IDBDatabase transaction!");
                    resolve(false);
                }
            }
            else {
                resolve(true);
            }
        });
    }

    /************************************/
    /* PLACE DATABASE                   */
    /************************************/

    async LoadPlaceData(placeid){
        return new Promise <StoredChanges>((resolve) => {
            if (!placeid){
                resolve(null);
            }
            else {
                let _request = this._OpenPlaceDatabase(placeid);
                _request.onerror = (event) => {
                    console.error("[STORAGE] Error opening indexeddb storage: ", _request.error);
                    resolve(null);
                }    

                _request.onsuccess = (event) => {
                    let _changesdb = (event.target as IDBOpenDBRequest).result;
                    
                    let _prMeta = this._LoadPlaceMeta(_changesdb);
                    let _prData = this._LoadPlaceData(_changesdb);

                    Promise.all([_prMeta, _prData]).then(
                    (results) => {
                        if ((results[0] != null) && (results[1] != null)){
                            resolve({ 
                                timestamp: results[0]['timestamp'], 
                                updates: results[1]
                            });    
                        }
                        else {
                            resolve(null);
                        }
                    });
                }        
            }
        });
    }

    async SavePlaceData(placeid, changes, timestamp){
        return new Promise((resolve) => {
            if (!placeid){
                resolve(null);
            }
            else {
                if (changes.length > 0){
                    let _timestamp = performance.now();
                    console.info("[STORAGE] writing [" + changes.length + "] entries for place (" + placeid + ") @ " + timestamp);
    
                    let _request = this._OpenPlaceDatabase(placeid);
                    _request.onerror = (event) => {
                        console.error("[STORAGE] Error opening indexeddb storage: ", _request.error);
                        resolve(null);
                    }        

                    _request.onsuccess = (event) => {
                        let _changesdb = (event.target as IDBOpenDBRequest).result;
    
                        this._SavePlaceData(_changesdb, changes).then(
                        (saved) => {
                            if (saved){
                                this._SavePlaceMeta(_changesdb, timestamp).then(
                                (saved) => {
                                    resolve(saved);
                                    console.info("[STORAGE] written [" + changes.length + "] entries in " + ((performance.now() - _timestamp)/1000).toFixed(2) + " seconds");
                                });
                            }
                            else {
                                resolve(false);
                            }
                        });
                    }    
                }
                else {
                    resolve(true);
                }
            }
        });
    }

    async ClearPlaceData(placeid){
        await this._CleanDatabase(this._place_indexeddb + placeid, [
            this._place_indexeddb_metadata, this._place_indexeddb_changest
        ]);
    }    

    /* private methods */

    private _OpenPlaceDatabase(placeid){
        let _indexeddb = this._place_indexeddb + placeid;
        let _request = indexedDB.open(_indexeddb);

        _request.onupgradeneeded = (event) => {
            let _db = (event.target as IDBOpenDBRequest).result;

            if (!_db.objectStoreNames.contains(this._place_indexeddb_metadata)) {
                _db.createObjectStore(this._place_indexeddb_metadata);
            }

            if (!_db.objectStoreNames.contains(this._place_indexeddb_changest)) {
                _db.createObjectStore(this._place_indexeddb_changest);
            }
        };

        _request.onblocked = (event) => {
            console.error("[STORAGE] The storage '" + _indexeddb + "' is blocked!!", event)
        }

        return _request;
    }

    private _LoadPlaceMeta(_changesdb){
        let _metadata = this._place_indexeddb_metadata;
        
        return new Promise((resolve) => {
            if (_changesdb && _changesdb.objectStoreNames.contains(_metadata)){
                try {
                    let _transaction = _changesdb.transaction([_metadata], 'readonly');
                    this._runinstore(_transaction.objectStore(_metadata), 'get', 0)
                    .then((target) => {
                        let _data = target.result;
                        if (_data && _data['timestamp']){
                            resolve({
                                timestamp: _data['timestamp']
                            });
                        }
                        else {
                            resolve(null);
                        }
                    })
                    .catch(() => {
                        resolve(null);    // no data
                    });    
                }
                catch(error){
                    console.error("[STORAGE] Error in IDBDatabase transaction!");
                    resolve(false);
                }
            }
            else {
                resolve(null);      // error or storage is unavailable
            }
        });
    }

    private _LoadPlaceData(_changesdb){
        let _changesst = this._place_indexeddb_changest;

        return new Promise((resolve) => {
            if (_changesdb && _changesdb.objectStoreNames.contains(_changesst)){
                try {
                    let _transaction = _changesdb.transaction([_changesst], 'readonly');
                    this._runinstore(_transaction.objectStore(_changesst), 'getAll')
                    .then((target) => {
                        let _data = [];
    
                        let _items = target.result || [];
                        for(let _item of _items){
                            _data.push(this.Decrypt(_item['change']));
                        }
    
                        resolve((_data.length == 0) ? null : _data);
                    })
                    .catch(() => {
                        resolve(null);    // no data
                    });    
                }
                catch(error){
                    console.error("[STORAGE] Error in IDBDatabase transaction!");
                    resolve(false);
                }
            }
            else {
                resolve(null);      // error or storage is unavailable
            }
        });
    }

    private _SavePlaceMeta(_changesdb, timestamp){
        let _metadata = this._place_indexeddb_metadata;

        return new Promise((resolve) => {
            let _data = {
                timestamp: timestamp
            };

            try {
                let _transaction = _changesdb.transaction([_metadata], 'readwrite');
                this._runinstore(_transaction.objectStore(_metadata), 'put', _data, 0)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);    // no data
                });    
            }
            catch(error){
                console.error("[STORAGE] Error in IDBDatabase transaction!");
                resolve(false);
            }
        });
    }

    private _SavePlaceData(_changesdb, changes){
        let _changest = this._place_indexeddb_changest;

        return new Promise((resolve) => {
            try {
                let _transaction = _changesdb.transaction([_changest], 'readwrite');
                let _objectstore = _transaction.objectStore(_changest);

                let _processbatch = (batch: any[]): Promise<void> => {
                    let _changepromises = [];
                    for(let _change of batch){
                        _changepromises.push(this._SaveChange(_objectstore, _change));
                    }
                        
                    return Promise.all(_changepromises).then(() => {});
                };

                let _batches = [];
                let _bchsize = 200;

                for (let i = 0; i < changes.length; i += _bchsize) {
                    _batches.push(changes.slice(i, i + _bchsize));
                }

                let _processbatches = async () => {
                    for (let _batch of _batches) {
                        await _processbatch(_batch);
                    }
                };
                
                _processbatches()
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    console.error("[STORAGE] Error saving changes:", error);
                    resolve(false);
                });
            }
            catch(error){
                console.error("[STORAGE] Error in IDBDatabase transaction!");
                resolve(false);
            }
        });
    }

    /************************************/
    /* REGISTRY LOCAL ACTIONS           */
    /************************************/

    async LogTicketAction(place, ticket){
        return new Promise((resolve) => {
            if (!place || !ticket){
                resolve(null);
            }
            else {
                let _request = this._OpenActionsDatabase(place.objid);
                _request.onerror = (event) => {
                    console.error("[STORAGE] Error opening indexeddb storage: ", _request.error);
                    resolve(null);
                }    
    
                _request.onsuccess = (event) => {
                    let _changesdb = (event.target as IDBOpenDBRequest).result;
    
                    this._LogTicketAction(_changesdb, place, ticket).then(
                    (saved) => {
                        resolve(saved);
                    });
                }    
             }    
        });
    }

    async GetActionsLog(place, _inidate, _enddate){
        return new Promise((resolve) => {
            if (!place){
                resolve(null);
            }
            else {
                // recover the actions log (in memory map)
                if (!(place.objid in this._placeactionsdata)){
                    let _request = this._OpenActionsDatabase(place.objid);
                    _request.onsuccess = async (event) => {
                        this._placeactionsdata[place.objid] = await this._ReadActionLog((event.target as IDBOpenDBRequest).result);
                    }
                }

                // convert to readable data (as ticketlog.php)
                if (place.objid in this._placeactionsdata){
                    resolve(this._ticketLog(place, _inidate, _enddate));
                }
                else {
                    resolve(null);
                }
             }    
        });
    }   
    
    /* private methods */
    
    private _OpenActionsDatabase(placeid){
        let _indexeddb = this._logactions_indexeddb + placeid;
        let _request = indexedDB.open(_indexeddb, 1);

        _request.onupgradeneeded = (event) => {
            let _db = (event.target as IDBOpenDBRequest).result;

            if (!_db.objectStoreNames.contains(this._logactions_indexeddb_actions)) {
                _db.createObjectStore(this._logactions_indexeddb_actions, { keyPath: 'logid',  autoIncrement: true });
            }
        };

        _request.onblocked = (event) => {
            console.error("[STORAGE] The storage '" + _indexeddb + "' is blocked!!", event)
        }

        return _request;
    }

    private async _DelLastAction(_changesdb){
        let _actionst = this._logactions_indexeddb_actions;
          
        let _transaction = _changesdb.transaction([_actionst], 'readwrite');
        let _objectstore = _transaction.objectStore(_actionst);

        try {
            let _target = await this._runinstore(_objectstore, 'openCursor');
            if (_target){
                let _cursor = _target.result;
                if (_cursor) {
                    await this._runinstore(_objectstore, 'delete', _cursor.key)
                    _cursor.continue();
                }
            }    
        }
        catch(error){
            console.error("[STORAGE] DelLastAction raised exception: ", error)

        }
    }

    private _ReadActionLog(_changesdb){
        let _actions = this._logactions_indexeddb_actions;
        
        return new Promise((resolve) => {
            if (_changesdb && _changesdb.objectStoreNames.contains(_actions)){
                let _transaction = _changesdb.transaction([_actions], 'readonly');
                this._runinstore(_transaction.objectStore(_actions), 'getAll')
                .then((target) => {
                    let _data = [];

                    let _items = target.result || [];
                    for(let _item of _items){
                        _data.push(_item);
                    }    

                    resolve(_data);
                })
                .catch(() => {
                    resolve([]);    // no data
                });
            }
            else {
                resolve([]);      // error or storage is unavailable
            }
        });
    }

    private _LogTicketAction(_changesdb, place, ticket){
        let _actionst = this._logactions_indexeddb_actions;

        return new Promise(async (resolve) => {
            if (!(place.objid in this._placeactionsdata) || !this._placeactionsdata[place.objid]){
                this._placeactionsdata[place.objid] = await this._ReadActionLog(_changesdb);
            }
    
            let _action = this._localAction(this._placeactionsdata[place.objid], ticket);
            let _timestamp = this._localDate(new Date());

            if (_action != 'NOCHANGES'){
                let _logentry = this._logEntry(_action, _timestamp, ticket);

                this._placeactionsdata[place.objid].push(_logentry);
                this._placeactionsdata[place.objid].slice(-1000);

                let _transaction = _changesdb.transaction([_actionst], 'readwrite');
                let _objectstore = _transaction.objectStore(_actionst);

                // add the lof entry to the storage and keep it below 1000 entries   
                if (await this._runinstore(_objectstore, 'add', _logentry)){
                    let _target = await this._runinstore(_objectstore, 'count')
                    if (_target){
                        let _size = _target.result;
                        while (_size >= 1000) {
                            await this._DelLastAction(_changesdb);
                            _size = _size - 1;
                        }
                    }

                    resolve(true);
                }
                else {
                    resolve(false);     // just in case
                }
            }
        });
    }
}

/************************************/
/* LOCAL DATABASE (STORAGE)         */
/************************************/

class CacheDatabase extends StoreDatabase {
    private _quicksave = true;       // set to true to keep data changes in memory

    private _storedddbbkey = 'upp-stored-ddbb_';
    private _storedactionskey = 'upp-stored-actions_'; 

    constructor(store: storeService, private _storage: Storage){
        super(store);
    }

    /************************************/
    /* SERVICE METHODS                  */
    /************************************/

    private Initialize(){
        return (this.store as any).Initialize();
    }

    private _GetStorage(key){
        return (this.store as any)._GetStorage(key);
    }

    private _SetStorage(key, value, warn = true){
        return (this.store as any)._SetStorage(key, value, warn);
    }

    private _DelStorage(key){
        return (this.store as any)._DelStorage(key);
    }

    /************************************/
    /* CLEANUP                          */
    /************************************/

    async ClearStorage(){
        let _promises = [];

        if (!this._storage){
            await this.Initialize();
        }

        _promises.push(this._DelStorage(this._storedddbbkey + 'session'));        // remove session data}
        this._storage.forEach((value, key, index) => {                            // remove all places data
            if (key.startsWith(this._storedddbbkey + 'place_')){
                _promises.push(this._DelStorage(key));
            }
        });

        await Promise.all(_promises);    
    }

    /************************************/
    /* SESSION DATABASE                 */
    /************************************/

    async LoadSessionData(deviceid, sessionid){
        return new Promise <StoredChanges>(async (resolve) => {
            if (!deviceid || !sessionid){
                resolve(null);
            }
            else {
                let _storekey = this._storedddbbkey + 'session';

                let _data = await this._GetStorage(_storekey);
                if (_data){
                    if ((_data['deviceid'] != deviceid) || (_data['sessionid'] != sessionid)){
                        resolve(null);  // not for this session
                    }
                    else {
                        let _now = new Date();
                        let _lst = new Date(_data['timestamp'].replace(' ', 'T') + '.000Z');

                        if ((_now.getTime() - _lst.getTime()) < 5 * 24 * 3600 * 1000){
                            resolve(_data);

                            // keep the data organized for quick save
                            if (this._quicksave){
                                this._SessionSave(_data['updates']);
                            }
                        }
                        else {
                            console.warn("[STORAGE] Stored data are outdated!");
                            resolve(null)
                        }
                    }
                }
                else {
                    resolve(null);  // no data
                }
            }
        });
    }

    async SaveSessionData(deviceid, sessionid, changes, timestamp){
        if (!deviceid || !sessionid){
            return;
         }
 
         if (changes.length > 0){
             let _storekey = this._storedddbbkey + 'session';
             if (!this._storage){
                 await this.Initialize();
             }
 
             let _updates = []
             let _stored = await this._SessionSave(changes);
             if (_stored){
                 for (let _table in _stored){
                     _updates = _updates.concat(Object.values(_stored[_table] || []));
                 }
             }
     
             console.info("[STORAGE] writting [" + _updates.length + "] entries for session");
             this._SetStorage(_storekey, {
                 deviceid: deviceid,
                 sessionid: sessionid,
                 timestamp: timestamp,
                 updates: _updates
             });            
         }
     }

    async ClearSessionData(){
        await this._DelStorage(this._storedddbbkey + 'session');        
    }    

    /* private methods */

    private _ddbbsessiondata = new Map();  // keeps place data changes in memory

    private async _SessionSave(_changes){
        if (this._quicksave){   // recover last changes from memory
            let _stored = this._ddbbsessiondata;

            for(let _change of _changes){
                if (typeof(_stored[_change['table']]) === 'undefined'){
                    _stored[_change['table']] = new Map();
                }
    
                _stored[_change['table']][_change['objid']] = this._MergeItem(_stored[_change['table']][_change['objid']],  _change);
            }
    
            this._ddbbsessiondata = _stored;
            return _stored;    
        }
        else {  // recover last changes from cache and reindex
            let _storeddata = await this._GetStorage(this._storedddbbkey + 'session');
            let _storedupdt = _storeddata ? _storeddata['updates'] : [];
            
            let _stored = new Map();
            for(let _update of _storedupdt){    // index the cache stored updates
                if (typeof(_stored[_update['table']]) === 'undefined'){
                    _stored[_update['table']] = new Map();
                }
    
                _stored[_update['table']][_update['objid']] = _update;
            }
    
            for(let _change of _changes){       // merge with the provided changes
                if (typeof(_stored[_change['table']]) === 'undefined'){
                    _stored[_change['table']] = new Map();
                }
    
                _stored[_change['table']][_change['objid']] = this._MergeItem(_stored[_change['table']][_change['objid']],  _change);
            }
    
            return _stored;    
        }
    }

    /************************************/
    /* PLACE DATABASE                   */
    /************************************/

    async LoadPlaceData(placeid){
        return new Promise <StoredChanges>(async (resolve) => {
            let _storekey = this._storedddbbkey + 'place_' + placeid;
            if (!this._storage){
                await this.Initialize();
            }

            if (!placeid){
                resolve(null);
            }
            else {
                let _data = await this._GetStorage(_storekey);
                resolve(_data);

                // keep the data organized for quick save
                if (_data && this._quicksave){
                    this._PlaceSave(placeid, _data['updates']);            
                }
            };
        });
    }

    async SavePlaceData(placeid, changes, timestamp){
        if (!placeid){
            return;
        }

        if (changes.length > 0){
            let _storekey = this._storedddbbkey + 'place_' + placeid;
            if (!this._storage){
                await this.Initialize();
            }

            let _updates = [];
            let _stored = await this._PlaceSave(placeid, changes);
            if (_stored){
                for (let _table in _stored){
                    _updates = _updates.concat(Object.values(_stored[_table] || []));
                }
            }
        
            let _timestamp = performance.now(); 
            console.info("[STORAGE] writing [" + _updates.length + "] entries for place (" + placeid + ") @ " + timestamp);
            this._SetStorage(_storekey, {
                timestamp: timestamp,
                updates: _updates
            });            
            console.info("[STORAGE] written [" + _updates.length + "] entries in " + ((performance.now() - _timestamp)/1000).toFixed(2) + " seconds");
        }
    }

    async ClearPlaceData(placeid){
        await this._DelStorage(this._storedddbbkey + 'place_' + placeid);        
    }    

    /* private methods */

    private _ddbbplacedata = new Map();;  // keeps place data changes in memory

    private async _PlaceSave(placeid, _changes){
        if (this._quicksave){   // recover last changes from memory
            let _stored = new Map();
            if (placeid in this._ddbbplacedata){
                _stored = this._ddbbplacedata[placeid];
            }
    
            for(let _change of _changes){
                if (typeof(_stored[_change['table']]) === 'undefined'){
                    _stored[_change['table']] = new Map();
                }
    
                // merge the current change with the stored instances
                if (!('_actn' in _change) || (_change['_actn'] != 'do_delete')){
                    let _item = _stored[_change['table']][_change['objid']];
                    _item = this._MergeItem(_item,  _change);
                    _stored[_change['table']][_change['objid']] = _item;
                }
                // delete the current change from the stored instances
                else {  
                    delete(_stored[_change['table']][_change['objid']]);
                }
            }
    
            this._ddbbplacedata[placeid] = _stored;
            return _stored;    
        }
        else {  // recover last changes from cache and reindex
            let _storeddata = await this._GetStorage(this._storedddbbkey + 'place_' + placeid);
            let _storedupdt = _storeddata ? _storeddata['updates'] : [];
            
            let _stored = new Map();;
            for(let _update of _storedupdt){    // index the cache stored updates
                if (typeof(_stored[_update['table']]) === 'undefined'){
                    _stored[_update['table']] = new Map();
                }
    
                _stored[_update['table']][_update['objid']] = _update;
            }
    
            for(let _change of _changes){       // merge with the provided changes
                if (typeof(_stored[_change['table']]) === 'undefined'){
                    _stored[_change['table']] = new Map();
                }
    
                _stored[_change['table']][_change['objid']] = this._MergeItem(_stored[_change['table']][_change['objid']],  _change);
            }
    
            return _stored;    
        }
    }

    /************************************/
    /* REGISTRY LOCAL ACTIONS           */
    /************************************/

    async LogTicketAction(place, ticket){
        let _storekey = this._storedactionskey + 'place_' + (place.objid);

        // recover the log when first entry is added
        if (!(place.objid in this._placeactionsdata) || !this._placeactionsdata[place.objid]){
            this._placeactionsdata[place.objid] = await this._ReadActionLog(_storekey);
        }
        
        let _action = this._localAction(this._placeactionsdata[place.objid], ticket);
        let _timestamp = this._localDate(new Date());

        if (_action != 'NOCHANGES'){
            let _logentry = this._logEntry(_action, _timestamp, ticket);
            
            this._placeactionsdata[place.objid].push(_logentry);
            this._placeactionsdata[place.objid].slice(-1000);
    
            this._SetStorage(_storekey, this._placeactionsdata[place.objid]);    
        }
    }

    async GetActionsLog(place, _inidate, _enddate){
        return new Promise <any> (async (resolve) => {
            let _storekey = this._storedactionskey + 'place_' + place.objid;

            // recover the actions log (in memory map)
            if (!(place.objid in this._placeactionsdata)){
                this._placeactionsdata[place.objid] = await this._ReadActionLog(_storekey);
            }

            // convert to readable data (as ticketlog.php)
            if (place.objid in this._placeactionsdata){
                resolve(this._ticketLog(place, _inidate, _enddate));
            }
            else {
                resolve(null);
            }
        });
    }   

    /* private methods */

    private _ReadActionLog(_storekey){
        return new Promise <any> ((resolve) => {
            this._GetStorage(_storekey).then(
            (actionlog) => {
                let _actionlog = actionlog ? actionlog : [];
                for(let _log in _actionlog){
                    this._placeinvoicesmap[_log['_uuid']] = _log['invceno']
                }

                resolve (_actionlog || []);
            });
        });
    }
}

@Injectable()
export class storeService {
    private _storage: Storage = null;

    constructor(private storage: Storage, private route: ActivatedRoute, private router: Router){
        this.Initialize();
    }

    /* readers / writers synchronization */

    private _wait_writing = new Map();

    private async _GetCompleted(key){
        let _wait = null;
        if (key in this._wait_writing){
            _wait = this._wait_writing[key].asObservable();
        }

        if (_wait){     // writing in progress
            console.log("[STORE] waiting for write [" + key + "] to be completed..")

            let _ini = performance.now();
            let _await = new Promise((resolve) => {
                let _subsctiption = _wait.subscribe(
                data => {
                    _subsctiption.unsubscribe();
                    
                    console.log("[STORE] write [" + key + "] completed in " + (performance.now() - _ini).toFixed(2) + " ms")
                    resolve(true);  // relase
                });
            });

            await _await;
        }

        return true;
    }

    private async _AddCompleted(key){
        await this._GetCompleted(key);

        // the key is free: block next ones
        let _subject = new Subject<any>();
        if (_subject){
            this._wait_writing[key] = _subject;
        }
    }

    private _DelCompleted(key){
        if (key in this._wait_writing){
            let _subject = this._wait_writing[key];
            if (_subject){
                _subject.next();    // notify
            }

            delete this._wait_writing[key];
        }        
    }

    /* Read / Write zipped compressed strings */

    private _docompress = false;    // compression is very CPU intensive!
    
    private _Compress(data){
        if (!data){
            return null;
        }
        
        if (this._docompress){
            return compress(JSON.stringify(data));
        }
        else {
            return JSON.stringify(data);
        }
    }

    private _Recover(data){
        if (!data){
            return null;
        }

        if (this._docompress){
            let _deflate = null;
            try {
                _deflate = decompress(data);
                if (_deflate){   
                    return JSON.parse(_deflate);   
                }
            } 
    
            // not compressed data
            catch (error) {     
                // nothing to do
            }    
        }

        try {
            return JSON.parse(data);
        }

        // not json encoded data
        catch (error) {     
            return null;
        }
    }

    /* Write to browser cache and release images on quota error */

    private _ReleaseImagesCache(){
        return new Promise((resolve) => {
            caches.keys().then(async keylist => {
                let _wpromises = [];
                for(let _key of keylist){
                    if (_key.indexOf('data:images') != -1){
                        let _opencache = await caches.open(_key);
                        if (_opencache){
                            let _openkeys = await _opencache.keys();
                            for (let _openkey of _openkeys){
                                _wpromises.push(_opencache.delete(_openkey.url));
                            }
                        }
                    }
                }

                if (_wpromises.length > 0){
                    Promise.all(_wpromises).then((results) => {
                        setTimeout(() => {
                            resolve(true);  // cache has been released
                        }, 0);
                    })
                }
                else {
                    resolve(false);     // cache was not found
                }
            });
        });
    }

    private async _GetStorage(key){
        await this._AddCompleted(key);
        let _data = await this._storage.get(key);
        this._DelCompleted(key);

        if (_data){
            return this._Recover(_data);
        }

        return null;
    }

    private async _SetStorage(key, value, warn = true){
        let _towrite = this._Compress(value);

        try {
            await this._AddCompleted(key);
            await this._storage.set(key, _towrite);
            this._DelCompleted(key);
        } catch (error) {
            let _error = "[STORAGE] Cannot set value in key '" + key + "' (" + value.length + " bytes) - " + error.name + "(" + error.message + ")";
            
            warn ? console.warn(_error) : console.error(_error);    // first is a warning, next are errors (recursive call)
            if (await this._ReleaseImagesCache()){
                this._SetStorage(key, value, false);
            }
        }
    }

    private async _DelStorage(key){
        console.info("[STORAGE] removing key '" + key + "'");

        await this._AddCompleted(key);
        await this._storage.remove(key);
        this._DelCompleted(key);

        // check that it has been removed (and clear if not)
        let _data = await this._GetStorage(key);
        if (_data){     // not removed workarround. Clear the content
            await this._SetStorage(key, null);
        }
    }    

    private _tabid = null;
    private Initialize(){
        this._tabid = GenericUtils.uuidv4();

        return new Promise(async (resolve) => {
            this._storage = await this.storage.create();
            resolve(this._storage != null);
        });
    }

    /************************************/
    /* LOGGER FUNCIONALITY              */
    /************************************/

    private _loggercampaignkey = 'upp-logger-campaignkey';

    GetLoggerCampaign(){
        let _campaign = localStorage.getItem(this._loggercampaignkey);
        if (_campaign){  // found in broser storage
            return (_campaign);
        }
        return null;    // not in browser storage
    }

    SetLoggerCampaign(value){
        localStorage.setItem(this._loggercampaignkey, value);
    }    

    /************************************/
    /* LOGIN FUNCIONALITY               */
    /************************************/

    private _storedloginkey = 'upp-stored-logindata';

    async SetStoredLogin(login: StoredLogin){
        if (!this._storage){
            await this.Initialize();
        }

        this._SetStorage(this._storedloginkey, login); 
    }

    async GetStoredLogin(deviceid){
        if (!this._storage){
            await this.Initialize();
        }

        let _storednfo : StoredLogin = await this._GetStorage(this._storedloginkey);
        if ((_storednfo) && (_storednfo.deviceid == deviceid)){
            return _storednfo;
        }

        return null;
    }

    async DelStoredLogin(){
        if (!this._storage){
            await this.Initialize();
        }

        await this._DelStorage(this._storedloginkey);
    }

    /************************************/
    /* SYNC FUNCIONALITY                */
    /************************************/

    private _storedsynckey = 'upp-stored-syncdata';
    private _storedsyncavl = false;     // avoid clearing if not set

    async SetChanges(changes: Array<SyncChange>){
        if (!this._storage){
            await this.Initialize();
        }

        if (changes.length > 0){
            this._SetStorage(this._storedsynckey, changes);
            this._storedsyncavl = true;     // changes available in storage
        }
    }

    async GetChanges(){
        if (!this._storage){
            await this.Initialize();
        }

        return await this._GetStorage(this._storedsynckey);
    }

    async ClearChanges(){
        if (this._storedsyncavl){
            this._storedsyncavl = false;    // removed changes form storage
            await this._DelStorage(this._storedsynckey);
        }
    }

    /************************************/
    /* TOKEN FUNCIONALITY               */
    /************************************/

    private _storedtokenkey = null;

    async SetToken(_token){
        this._storedtokenkey = _token;

        // https://stackoverflow.com/questions/46213737/angular-append-query-parameters-to-url
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              token: this._storedtokenkey
            },

            queryParamsHandling: 'merge',   // preserve the existing query params in the route
            skipLocationChange: false       // update the url params
          });
    }

    async UpdateToken(token: SessionToken){
        if (!this._storage){
            await this.Initialize();
        }

        if (this._storedtokenkey){
            this._SetStorage(this._storedtokenkey, token);
        }
    }

    async RecoverToken(){
        if (!this._storage){
            await this.Initialize();
        }

        return await this._GetStorage(this._storedtokenkey);
    }

    async ReleaseToken(){
        await this._DelStorage(this._storedtokenkey);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              token: null
            },

            queryParamsHandling: 'merge',   // preserve the existing query params in the route
            skipLocationChange: false       // update the url params
        });

        this._storedtokenkey = null;
    }

    /************************************/
    /* DEVICEID FUNCIONALITY            */
    /************************************/

    private _storeddevicekey = 'upp-stored-deviceuuid-v2';

    async GetDevice(){
        if (!this._storage){
            await this.Initialize();
        }

        let _uuid = localStorage.getItem(this._storeddevicekey);
        if (_uuid){  // found in broser storage
            return (_uuid);
        }
        else {      // not in browser storage - check in local ddbb
            return (await this._GetStorage(this._storeddevicekey));
        }
    }

    async SetDevice(uuid){
        localStorage.setItem(this._storeddevicekey, uuid);

        if (!this._storage){
            await this.Initialize();
        }

        this._SetStorage(this._storeddevicekey, uuid);
    }

    /************************************/
    /* LAST SERVED TICKETS              */
    /************************************/

    private _storedticketkey = 'upp-stored-ticketserved';

    GetLastTicket(place, serial, type = null){
        let _ltserved = { T: 0, R: 0, F: 0, A: 0, C: 0, last: null };
        
        let _storekey = this._storedticketkey + '_' + place.objid + '_' + serial;
        let _storeval = localStorage.getItem(_storekey);
        if (_storeval){
            _ltserved = Object.assign({}, _ltserved, JSON.parse(_storeval));
        }

        if (type){  // type is informed: requesting ticket number
            _ltserved[type] += 1;
            localStorage.setItem(_storekey, JSON.stringify(_ltserved));  
            
            let _invoice = type + ("00000000" + _ltserved[type]).slice(-8);  
            return _invoice;  
        }
        
        return _ltserved['last'];   // requesting last ticket information  
    }

    SetLastTicket(place, serial, _ltserved){
        let _current = { T: 0, R: 0, F: 0, A: 0, C: 0, last: null };

        let _storekey = this._storedticketkey + '_' + place.objid + '_' + serial;
        let _storeval = localStorage.getItem(_storekey);
        if (_storeval){
            _current = JSON.parse(_storeval);
        }
        
        for(let type of [ 'T', 'R', 'F', 'A', 'C']){
            if (type in _ltserved){
                _current[type] = _ltserved[type];   // update the value in current data
                console.info("[DEVICE TICKET] Last served: " + type + ("00000000" + _ltserved[type]).slice(-8));
            }
        }    

        if ('last' in _ltserved){
            _current['last'] = _ltserved['last'];
        }

        localStorage.setItem(_storekey, JSON.stringify(_current));      
    }

    /************************************/
    /* LOCAL DATABASE FUNCIONALITY      */
    /************************************/

    private _storedddbbversion = 'upp-stored-ddbb_version';

    private _storage_database: StoreDatabase = null;
    private async _database(){
        if (this._storage_database == null){
            let _indexed = (window.indexedDB) && (('databases' in indexedDB));
            if (!_indexed){
                console.warn("[STORAGE] indexedDB databases API is not supported in this browser.")
                if (!this._storage){
                    await this.Initialize();
                }

                this._storage_database = new CacheDatabase(this, this._storage)
            }
            else {
                this._storage_database = new IndexDatabase(this)
            }
        }

        return (this._storage_database);
    }

    private async ClearStorage(){
        let _database = await this._database();
        if (_database){
            _database.ClearStorage();
        }
    }

    async ForceObsoleteData(){
        let _storekey = this._storedddbbversion;
        this._SetStorage(_storekey, { 
            version: 0, 
            restored: 0 
        });  
    }

    CheckObsoleteData(version, restored){
        return new Promise((resolve) => {
            // storage is obsolete if server version is updated
            let _storekey = this._storedddbbversion;
            this._GetStorage(_storekey).then(
            async _data => {
                console.info("[STORAGE] Checking for server version update..")
                let _obsolete = false;

                // check for data expiration date
                if (!_obsolete){
                    if (_data && ('expires' in _data) && _data['expires'] < (new Date()).getTime()){
                        console.warn("[STORAGE] Stored data are obsolete (more than 7 days old)!");    
                        await this.ClearStorage();
                        _obsolete = true;
                    }    
                }

                // check for service version or database update
                if (!_obsolete){
                    if (_data && ((_data.version != version) || (_data.restored != restored))){
                        console.warn("[STORAGE] Stored data are obsolete (valid for version '" + _data.version + ", restored at: " + _data.restored + "')!");    
                        await this.ClearStorage();
                        _obsolete = true;
                    }
                }

                // calculate new expiration date
                let _expires = (_data && ('expires' in _data)) ? _data['expires'] : 0;
                if (_obsolete){ 
                    let _expiration_date = new Date();
                    _expiration_date.setDate(_expiration_date.getDate() + 7);    
                    _expires = _expiration_date.getTime();
                }
                else {
                    console.info("[STORAGE] Server version is valid.")
                }
    
                // update the stored server version
                this._SetStorage(_storekey, { 
                    version: version, 
                    restored: restored, 
                    expires: _expires
                });  

                resolve(true)
            });    
        });
    }

    async LoadSessionData(deviceid, sessionid){
        let _database = await this._database();
        if (_database){
            return await _database.LoadSessionData(deviceid, sessionid);
        }

        return null;    // no database?
    }

    async SaveSessionData(deviceid, sessionid, changes, timestamp){
        let _database = await this._database();
        if (_database){
            return await _database.SaveSessionData(deviceid, sessionid, changes, timestamp);
        }

        return null;    // no database?
    }

    async ClearSessionData(){
        let _database = await this._database();
        if (_database){
            return await _database.ClearSessionData();
        }

        return null;    // no database?
    }

    async LoadPlaceData(placeid){
        let _database = await this._database();
        if (_database){
            return await _database.LoadPlaceData(placeid);
        }

        return null;    // no database?
    }

    async SavePlaceData(placeid, changes, timestamp){
        let _database = await this._database();
        if (_database){
            return await _database.SavePlaceData(placeid, changes, timestamp);
        }

        return null;    // no database?
    }

    async ClearPlaceData(placeid){
        let _database = await this._database();
        if (_database){
            return await _database.ClearPlaceData(placeid);
        }

        return null;    // no database?
    }

    /************************************/
    /* LOCAL REGISTRY CHANGES           */
    /************************************/   

    async LogTicketAction(place, ticket){
        let _database = await this._database();
        if (_database){
            return await _database.LogTicketAction(place, ticket);
        }

        return null;    // no database?
    }

    async GetActionsLog(place, _inidate, _enddate){
        let _database = await this._database();
        if (_database){
            return await _database.GetActionsLog(place, _inidate, _enddate);
        }

        return null;    // no database?
    }

    /************************************/
    /* SECURED SESSION STORAGE          */
    /************************************/    

    private _cryptokey = null;
    private async CryptoKey(){
        if (this._cryptokey == null){
            try {
                let _cryptokey = await window.crypto.subtle.generateKey(
                    { name: "AES-GCM", length: 256 },
                    true,
                    ["encrypt", "decrypt"]
                );

                this._cryptokey = _cryptokey;
            } 
            catch (error) {
                console.error("[STORAGE] Could not generate crypto key", error);
            }
        }

        return this._cryptokey;
    }

    async AddSessionData(key, data){
        let _storagedata = null;

        let _cryptokey = await this.CryptoKey()
        if (_cryptokey){
            let _iv = window.crypto.getRandomValues(new Uint8Array(12));
            try {
                let _encoded = new TextEncoder().encode(data);
                let _encrypted = await window.crypto.subtle.encrypt(
                    { name: "AES-GCM", iv: _iv },
                    _cryptokey,
                    _encoded
                );

                let _iv_b64 = GenericUtils.uint8ArrayToBase64(_iv);
                let _dt_b64 = GenericUtils.arrayBufferToBase64(_encrypted);

                if (_dt_b64 && _iv_b64){
                    _storagedata = { data: _dt_b64, iv: _iv_b64 };
                }
            } 
            catch (error) {
                console.error("[STORAGE] Could not cypher storage data", error);
            }

            if (_storagedata){
                sessionStorage.setItem(this._tabid + '__' + key, JSON.stringify(_storagedata));
            }    
        } 
    }

    async GetSessionData(key){
        let _cryptokey = await this.CryptoKey()
        if (_cryptokey){
            let _storagedata = JSON.parse(sessionStorage.getItem(this._tabid + '__' + key));
            if (_storagedata){
                try {
                    let _iv = GenericUtils.base64ToUint8Array(_storagedata['iv']);
                    let _encrypted = GenericUtils.base64ToArrayBuffer(_storagedata['data']);

                    if (_encrypted && _iv){
                        let decrypted = await window.crypto.subtle.decrypt(
                            { name: "AES-GCM", iv: _iv },
                            _cryptokey,
                            _encrypted
                        );    

                        return new TextDecoder().decode(decrypted);
                    }
                } 
                catch (error) {
                    console.error("[STORAGE] Could not de-cypher storage data", error);
                }
            }
        } 

        return null;    // no data?
    }
}