import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { CRC32 } from '@app/modules/sync';

import { AppConstants } from '@app/app.constants';
import { toastService } from '@app/modules/common/toast'; 
import { languageService} from '@app/modules/common/language'; 
import { platformService } from '@app/modules/common/platform';
import { downloadService } from '@app/modules/common/upload';
import { logsService } from '@app/modules/logs';
import { syncService } from '@app/modules/sync';
import { storeService } from '@app/modules/store';
import { viewService } from '@app/modules/view';
import { reportService } from '@app/modules/report';

import { BaseObject, DataObject } from '@app/modules/model/base';
import { FCM } from '@app/modules/model/fcm';
import { Address } from '@app/modules/model/address';
import { AskWaiter } from '@app/modules/model/askwaiter';
import { CashChange } from '@app/modules/model/cashchange';
import { Audit } from '@app/modules/model/audit';
import { AuditInfo } from '@app/modules/model/auditinfo';
import { Extra } from '@app/modules/model/extra';
import { ExtraProductOption } from '@app/modules/model/extraoption';
import { ExtraProduct } from '@app/modules/model/extraproduct';
import { ExtraQrCode } from '@app/modules/model/extratable';
import { Offer } from '@app/modules/model/offer';
import { OfferProductOption } from '@app/modules/model/offeroption';
import { OfferProduct } from '@app/modules/model/offerproduct';
import { Discount } from '@app/modules/model/discount';
import { Payment } from '@app/modules/model/payment';
import { StripeAccount } from '@app/modules/model/stripe';
import { OfferPeriod } from '@app/modules/model/period';
import { ExtraPeriod } from '@app/modules/model/period';
import { Place } from '@app/modules/model/place';
import { PlaceArea } from '@app/modules//model/placearea';
import { PlaceLink } from '@app/modules/model/placelink';
import { PlaceOption } from '@app/modules/model/placeoption';
import { PlaceService } from '@app/modules/model/svcplace';
import { ServiceConnect }  from '@app/modules/model/svcconnect';
import { PrintDevice } from '@app/modules/model/printer';
import { ScaleDevice } from '@app/modules/model/scale';
import { PrintProductOption } from '@app/modules/model/printoption';
import { PrintProduct } from '@app/modules/model/printproduct';
import { Business } from '@app/modules/model/business';
import { PayAccount } from '@app/modules/model/payaccount';
import { AccountInvoice } from '@app/modules/model/accountinvoice';
import { Product } from '@app/modules/model/product';
import { ProductCategory } from '@app/modules/model/productcategory';
import { ProductDepend } from '@app/modules/model/productdepend';
import { ProductOption } from '@app/modules/model/productoption';
import { ProductSelect } from '@app/modules/model/productselect';
import { SelectOption } from '@app/modules/model/selectoption';
import { Family } from '@app/modules/model/family';
import { FamilyPeriod } from '@app/modules/model/period';
import { FamilyProduct } from '@app/modules/model/familyproduct';
import { QrCode } from '@app/modules/model/qrcode';
import { Session } from '@app/modules/model/session';
import { Ticket } from '@app/modules/model/ticket';
import { TicketChange } from '@app/modules/model/ticketchange';
import { TicketBAI } from '@app/modules/model/ticketbai';
import { TicketSII } from '@app/modules/model/ticketsii';
import { TicketInvoice } from '@app/modules/model/ticketinvoice';
import { TicketExtra } from '@app/modules/model/ticketextra';
import { TicketOffer } from '@app/modules/model/ticketoffer';
import { TicketOption } from '@app/modules/model/ticketoption';
import { TicketProduct } from '@app/modules/model/ticketproduct';
import { TicketDiscount } from '@app/modules/model/ticketdiscount';
import { User } from '@app/modules/model/user';
import { UserAddress } from '@app/modules/model/useraddress';
import { Waiter } from '@app/modules/model/waiter';
import { DrawItem } from '@app/modules/model/draw';

import { _Product } from '@app/modules/model/product';
import { _ProductCategory } from '@app/modules/model/productcategory';
import { _ProductSelect } from '@app/modules/model/productselect';
import { _ProductOption } from '@app/modules/model/productoption';
import { _ProductDepend } from '@app/modules/model/productdepend';
import { GenericUtils } from '@app/app.commonutils';

/************************************/
/* PRODUCTS CATALOG                 */
/************************************/

class Catalog {
    private _doCatalogUrl = 'catalog/catalog.php';

    constructor(private sync: syncService, private data: dataService){
        // nothing to do
    }

    /*********************************/
    /* LOADING METHODS               */
    /*********************************/

    private _toCtgref(place, ctgref){
        return place.objid + "_" + ctgref;
    }

    private _getData(data, field, deflt = null) {
        return (field in data) ? data[field] : deflt;
    }

    private _getPhoto(data, field, deflt = null) {
        return (field in data) ? AppConstants.baseURL + 'catalog/image/' + data[field] : deflt;
    }

    private _findOption(product: Product, data: string){
        if (data){
            for(let _category of product.categories){
                for(let _option of _category.options){
                    if (_option.ctgref == data){
                        return _option;
                    }
                }
            }
            console.error("[CATALOG] option '" + data + "' not found (problem in catalog?)");
        }

        return null;
    }

    private _findProduct(place: Place, data: string){
        if (data){
            let _data = this._toCtgref(place, data);
            for(let _product of place.products){
                if (_product.ctgref == _data){
                    return _product;
                }
            }
            console.error("[CATALOG] product '" + data + "' not found (problem in catalog?)");    
        }

        return null;
    }

    private _readOption(category, data){
        let _ddbb: _ProductOption = {
            sort: 0,
            status: 'CT',
            active: false,
            catalog: true,
            product: null,
            category: null,
            price: null,

            ctgref: this._getData(data, 'ref', null), 
            name: this._getData(data, 'name', null),
            description: this._getData(data, 'description', null)
        };

        let _option = new ProductOption(null, this.data, { volatile: true });
        if (_option){
            _option.Info = _ddbb;
            _option.product = category.product;
            _option.category = category;
            _option.IsLoaded = true;
            _option.IsCatalog = true;
        }

        return _option;
    }

    private _readDepend(product: Product, category: ProductCategory, data: string) {
        let _depend = new ProductDepend(null, this.data, { volatile: true });
        if (_depend){
            _depend.category = category;
            _depend.option = this._findOption(product, data);
            _depend.IsLoaded = true;
            _depend.IsCatalog = true;
        }
        return _depend;
    }

    private _readCategory(product, data){
        let _ddbb: _ProductCategory = {
            sort: 0,
            status: 'CT',
            product: null,

            name: this._getData(data, 'name', null),
            type: this._getData(data, 'type', null),
            fxmin: this._getData(data, 'fixed', [0, 0])[0],
            fxmax: this._getData(data, 'fixed', [0, 0])[1]
        };

        let _category = new ProductCategory(null, this.data, { volatile: true });
        if (_category){
            _category.Info =_ddbb;   
            _category.product = product;

            // add 'options' children
            let _options = this._getData(data, 'options', []);
            for (let _data of _options){
                let _option : ProductOption = this._readOption(_category, _data);
                if (_option){
                    _category.AddOption(_option);
                }
            }

            // add 'depends' children
            let _depends = this._getData(data, 'depends', []);
            for (let _data of _depends){
                let _depend: ProductDepend = this._readDepend(product, _category, _data);
                if (_depend){
                    _category.AddDepend(_depend);
                }
            }

            _category.IsLoaded = true;
            _category.IsCatalog = true;
        }

        return _category;
    }

    private _readSelect(product, data){
        let _ddbb: _ProductSelect = {
            sort: 0,
            product: null,
            name: this._getData(data, 'name', null),
            code: null,
            photo: {
                url: this._getPhoto(data, 'photo', null),
                b64: null
            },
            description: this._getData(data, 'description', null),
        };

        let _select = new ProductSelect(null, this.data);
        if (_select){
            _select.Info = _ddbb;
            _select.product = product;

            // add 'options' children
            let _options = this._getData(data, 'options', []);
            for (let _data of _options){
                let _option: SelectOption = new SelectOption(null, this.data, { volatile: true });
                if (_option){
                    _option.select = _select;
                    _option.option = this._findOption(product, _data);
                    _option.status = 'AC';
                    _select.AddOption(_option);
                }
            }

            _select.IsLoaded = true;
            _select.IsCatalog = true;
        }

        return _select;
    }

    private _readProduct(place, data){
        let _ddbb: _Product = {
            sort: 0,
            status: 'CT',
            place: null,
            price: null,

            ctgref: this._toCtgref(place, this._getData(data, 'ref', null)),
            name: this._getData(data, 'name', null),
            code: null,
            photo: {
                url: this._getPhoto(data, 'photo', null),
                b64: null
            },
            description: this._getData(data, 'description', null),
            isgroup: this._getData(data, 'isgroup', false),
            isfamily: this._getData(data, 'isfamily', false),
            generic: false,
            type: this._getData(data, 'type', null),
            parent: this._getData(data, 'parent', null),
            flags: null
        };

        let _product = new Product(null, this.data, { volatile: true });
        if (_product){
            _product.Info = _ddbb;
            _product.parent = this._findProduct(place, this._getData(data, 'parent', null));

            // add 'categories' children
            let _categories = this._getData(data, 'categories', []);
            for (let _data of _categories){
                let _category : ProductCategory = this._readCategory(_product, _data);
                if (_category){
                    _product.AddCategory(_category);
                }
            }

            // add 'selects' children
            let _preselects = this._getData(data, 'selects', []);
            for (let _data of _preselects){
                let _select : ProductSelect = this._readSelect(_product, _data);
                if (_select){
                    _product.AddSelect(_select);
                }
            }

            _product.IsLoaded = true;
            _product.place = place;
            _product.IsCatalog = true;
        }

        return _product;
    }

    private _addProduct(place: Place, product: Product) {
        let _candidate: Product = null;
        for (let _product of place.products){
            if (_product.IsValid && !_product.Catalog && (_product.ctgref == product.ctgref)){
                _candidate = _product;
            }

            if (_candidate){
                break;  // product found in place
            }
        }

        // add the catalog item to the existing place product
        if (_candidate){
            _candidate.Catalog = product;
        }

        // add the catalog item to the place (as new product)
        else {
            place.AddProduct(product);
        }
    }

    private _loadCatalog(place: Place) {
        console.info("[CATALOG] Loading products catalog..");

        return new Promise((resolve) => {
            this._isloaded = true;

            if (place.objid == null){
                console.warn("[CATALOG] Delay catalog load until place is commited");
                let _subscription = place.OnCommit.subscribe(
                data => {
                    _subscription.unsubscribe();
                    this._loadCatalog(place);
                })
            }
            else {
                this.sync.DoRequest(this._doCatalogUrl)
                .then(catalog => {
                    this.sync.Clock.ClockEnable('catalog', false);
                    for(let data of catalog as Array<any>){
                        let _product : Product = this._readProduct(place, data);
                        if (_product){
                            this._addProduct(place, _product);
                        }            
                    }
                    this.sync.Clock.ClockEnable('catalog', true);

                    console.info("[CATALOG] Loaded succesfully.");  
                    resolve(true);
                }, error => {
                    this._isloaded = false;

                    console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doCatalogUrl + "'");
                    resolve(false);
                });         
            }    
        });
    }

    /****************************/
    /* CATALOG INITIALIZATION   */
    /****************************/

    private _isloaded = false;
    get IsLoaded(): boolean {
        return this._isloaded;
    }

    DoLoad(place: Place){
        return this._loadCatalog(place);
    }
}

/************************************/
/* DATA STORAGE                     */
/************************************/

class Storage {
    private _objects = {
        resolved: {},
        awaiting: {}
    };   

    constructor(){
        // nothing to do
    }

    /********************************/
    /* PRIVATE METHODS              */
    /********************************/

    private _GetObject(table: string, objref: string, _target: any): BaseObject{
        if (!(table in _target)){
            return null;    // table not found
        }

        return _target[table].get(objref) || null;
    }

    private _AddObject(dataobject: BaseObject, _target: any) : boolean{
        if (!(dataobject.table in _target)){
            _target[dataobject.table] = new Map <string, BaseObject>();
        }

        if (this._GetObject(dataobject.table, dataobject.objref, _target)){
            return false;   // already exists
        }

        _target[dataobject.table].set(dataobject.objref, dataobject);

        return true;
    }

    private _DelObject(dataobject: BaseObject, _target: any){
        if (dataobject.table in _target) {
            _target[dataobject.table].delete(dataobject.objref)
        }
    }

    /********************************/
    /* PUBLIC METHODS               */
    /********************************/

    GetByRef(table: string, objid: string, _uuid: string): BaseObject {
        let _object = null;

        if (!_object){
            _object = this._GetObject(table, _uuid, this._objects.awaiting);
        }

        if (!_object){
            _object = this._GetObject(table, objid, this._objects.resolved);
        }

        return _object;
    }

    GetObject(dataobject: BaseObject): BaseObject {
        let _object = null;

        if (!_object){
            _object = this._GetObject(dataobject.table, dataobject.objref, this._objects.awaiting);
        }

        if (!_object){
            _object = this._GetObject(dataobject.table, dataobject.objref, this._objects.resolved);
        }

        return _object;
    }

    AddObject(dataobject: BaseObject): boolean {
        if (dataobject.CopyOf || !dataobject.ToStorage(this)){
            return true;    // not suitable for storage 
        }

        if (dataobject.objid && !isNaN(Number(dataobject.objid))){
            return this._AddObject(dataobject, this._objects.resolved);
        }
        else {
            return this._AddObject(dataobject, this._objects.awaiting);
        }    
    }

    DelObject(dataobject: BaseObject) {
        if (dataobject.objid && !isNaN(Number(dataobject.objid))){
            return this._DelObject(dataobject, this._objects.resolved);
        }
        else {
            return this._DelObject(dataobject, this._objects.awaiting);
        }    
    }

    SetObject(dataobject: BaseObject) {
        if (dataobject){
            this.DelObject(dataobject);
            this.AddObject(dataobject);    
        }
    }

    // resolve an item in awaiting and add it to resolved
    Resolve(dataobject: BaseObject){
        if (dataobject.objid){
            return; // this object is already resolved
        }

        this._DelObject(dataobject, this._objects.awaiting);

        dataobject.objid = dataobject.Info['objid'] ? dataobject.Info['objid'].toString() : null;

        let _to_delete: BaseObject = this._GetObject(dataobject.table, dataobject.objref, this._objects.resolved);
        let _to_insert: BaseObject = dataobject;

        if (_to_delete){
            this._DelObject(_to_delete, this._objects.resolved);            
        }

        if (dataobject.objid){  // object resolution
            this._AddObject(_to_insert, this._objects.resolved);
        }
        else {                  // object replacement
            this._AddObject(_to_insert, this._objects.awaiting);
        }

        if (_to_delete){
            _to_delete.OnResolve();   // resolve references for the deleted object            
        }
    }

    // replace one item in resolved with other (updated) item
    Replace(dataobject: BaseObject, uuid: string){
        let _to_update = this._GetObject(dataobject.table, uuid, this._objects.awaiting);
        let _to_delete: BaseObject = this._GetObject(dataobject.table, dataobject.objref, this._objects.resolved);
        let _to_insert: BaseObject = dataobject;
        
        if (_to_update){
            this._DelObject(_to_update, this._objects.awaiting);
        }

        if (_to_delete){
            this._DelObject(_to_delete, this._objects.resolved); 
        }

        this._AddObject(_to_insert, this._objects.resolved)

        if (_to_update){
            _to_update.OnResolve();     // resolve references for the updated object
        }

        if (_to_delete){
            _to_delete.OnResolve();     // resolve references for the deleted object
        }
    }

    Clear(){
        this._objects.resolved = {};
        this._objects.awaiting = {};
    }
}

/************************************/
/* DATA TRANSACTIONS                */
/************************************/

interface _TransactionItem { item: DataObject, forcedCommit: boolean };

export class Transaction {
    private _start = false;
    private _items: Array<_TransactionItem> = null;

    constructor(public data: dataService){
        this._items = [];
        this._start = true;
    }

    private push(item: DataObject, force: boolean){
        if (!this._start){
            console.error("[TRANSACTION] You must initialize the transaction first");
        }
        else {
            this._items.push({ item: item, forcedCommit: force });
        }
    }

    SendCommit(item: DataObject){
        this.push(item, false);
    }

    ForceCommit(item: DataObject){
        this.push(item, true);
    }

    private _IndexToCommit(needle, haystack){
        for(let _idx = 0; _idx < haystack.length; _idx++){
            if (this._items[_idx].item == needle){
                return _idx;
            }
        }

        return -1;  // not found;
    }

    async Flush(force: boolean = false){
        if (!this._start){
            console.error("[TRANSACTION] You must initialize the transaction first");
        }
        else {
            if (this._items && (this._items.length > 0)){
                // remove item duplicates (and preserve the forced commits)
                let _incommit: Array <_TransactionItem> = [];
                for(let _tocommit of this._items){
                    let _idx = this._IndexToCommit(_tocommit.item, _incommit);
                    if (_idx != -1){    // preserve the forced commits
                        _incommit[_idx].forcedCommit = _tocommit.forcedCommit || _incommit[_idx].forcedCommit;
                    }
                    else {  
                        _incommit.push({ item: _tocommit.item, forcedCommit: _tocommit.forcedCommit })
                    }
                }
    
                // commit all items and wait for completion
                let _towait = [];
                for(let _tocommit of _incommit){
                    _towait.push((_tocommit.forcedCommit) ? _tocommit.item.ForceCommit() : _tocommit.item.DoCommit());
                }
                await Promise.all(_towait);
            }
        }

        return this.data.CommitTransaction(this, force);
    }
}

export { Storage as dataStorage };

/************************************/
/* DATA SERVICE                     */
/************************************/

@Injectable()
export class dataService implements OnDestroy {    
    private _subscriptions = [];

    CRC32(value: string){
        return new CRC32(value).value;
    }

    private _session: Session = null;
    get session(): Session {
        return this._session;
    }

    get device(): string {
        if (this.sync.Session){
            return this.sync.Session.device;
        }
        return null;
    }

    private _serial = null;
    get serial(): string {
        if (!this._serial){
            this._serial = this.sync.Session ? this.sync.Session.serial : null;
        }
        return this._serial;
    }

    get isKiosk(): boolean {
        return !this.qrcode && this.platform._isKiosk;
    }

    set isKiosk(value: boolean){
        this.platform._isKiosk = !this.qrcode && value;
    }
    
    get ViewMode(): string {
        return (this.platform._isMobile) ? 'mobile' : 'desktop';
    }

    set ViewMode(value: string){
        if (value && (this.ViewMode != value)){
            this.platform._viewMode = value;
        }
    }

    get ViewTheme(): string {
        return this.view.Theme;
    }

    set ViewTheme(value: string) {
        if (value && (this.ViewTheme != value)){
            this.view.Theme = value;
        }
    }

    get ZoomLevel(): number{
        return this.platform._zoomLevel || 1;
    }

    set ZoomLevel(value: number){
        if ((value >= 0.1) && (value <= 1.0)){
            this.platform._zoomLevel = value;
        }
    }

    get Scrollbar(): boolean{
        return this.view.Scrollbar;
    }

    set Scrollbar(value: boolean){
        this.view.Scrollbar = value;
    }

    get LoggerCampaign(): string{
        return this.logs.Campaign;
    }

    set LoggerCampaign(value: string){
        if (value == this.LoggerCampaign){
            return;     // no changes
        }

        if (value){
            this.logs.Start(value);
            console.info("[LOGGER] Starting campaign '" +  this.LoggerCampaign + "'")
        }
        else {
            console.info("[LOGGER] Stopping campaign '" +  this.LoggerCampaign + "'")
            this.logs.Stop();
        }
    }

    get Clock(){
        return this.sync.Clock;
    }

    /********************************/
    /* SERVER STORED                */
    /********************************/

    private _reports = null;
    get Reports(){
        if (this._reports == null){
            this._reports = new reportService(this.lang, this.toast, this, this.sync);
        }
        return this._reports;
    }

    /********************************/
    /* SESSION USER                 */
    /********************************/

    private _reloadRequested = new Subject<any> ();
    public OnReloadRequested = this._reloadRequested.asObservable();

    private _user: User = null;
    get user(): User {
        return this._user;
    }

    private _user_subscription = null;
    async SetUser(user: User){
        if (this._user != user){
            this.SetObject(user);

            await this.sync.ResetStage();
            this._user = user;

            this.sync.SetUser(user).then(
            data => {
                // nothing to do
            });

            if (user){
                this.lang.SetLanguage(user.lang);
    
                // notify user online status to other clients
                let _user = user || this._user;
                let _transaction = await this.BeginTransaction();
                if (_transaction){
                    for(let _waiter of _user.waiters){
                        if (_waiter.IsValid){
                            _transaction.ForceCommit(_waiter);
                        }
                    }    

                    _transaction.Flush();
                }
            }
        
            if (this.user){
                if (this._user.objid){
                    console.info("[USER] " + this._user.fullname + " (language: " + this.user.lang + ")");
                }
                else {
                    console.info("[USER] (guest user)")
                }
    
                this._user_subscription = this.user.OnRefresh.subscribe(
                data => {
                    this.lang.SetLanguage(this.user.lang);
                })
            }
            else {
                if (this._user_subscription){
                    this._user_subscription.unsubscribe();
                    this._user_subscription = null;
                }
            }    
        }

        this._reloadRequested.next();
    }

    /********************************/
    /* SESSION PLACE                */
    /********************************/

    private _catalog: Catalog = null;
    get catalog() : Catalog {
        if (!this._catalog){
            this._catalog = new Catalog(this.sync, this);
        }
        return this._catalog;
    }

    private _place: Place = null;
    get place(): Place {
        if (this.qrcode && this.qrcode.place){
            return this.qrcode.place;
        }

        if (this.ticket && this.ticket.place) {
            return this.ticket.place;
        }

        return this._place;
    }

    async SetPlace(place: Place){
        if (this.place != place){
            this.SetObject(place);

            await this.sync.ResetStage();
            this._place = place;
            this._catalog = null;
  
            this.sync.SetPlace(place).then(
            data => {
                if (place != null){
                    this.view.View = 'PLACE';
                }
            });    
            
            this._reloadRequested.next();
        }
    }

    /********************************/
    /* SESSION TABLE                */
    /********************************/

    private _qrcode: QrCode = null;
    get qrcode(){
        return this._qrcode;
    }

    async SetTable(qrcode: QrCode) {
        if (this.qrcode != qrcode){   
            this.SetObject(qrcode);
    
            await this.sync.ResetStage();
            this._qrcode = qrcode;                

            this.sync.SetTable(qrcode).then(
            data => {
                // nothing to do
            });
        
            // notify table online status to other clients
            let _qrcode = qrcode || this._qrcode;
            if (_qrcode){
                _qrcode.ForceCommit();
            }

            this._reloadRequested.next();
        }
    }
    /********************************/
    /* SESSION TICKET               */
    /********************************/

    private _ticket: Ticket = null;
    get ticket(){
        return this._ticket;
    }

    async SetTicket(ticket: Ticket) {
        if (this.ticket != ticket){     
            this.SetObject(ticket);

            this.sync.SetTicket(ticket).then(
            data => {
                // nothing to do
            });
    
            this._ticket = ticket;
        }

        this._reloadRequested.next();
    }

    /************************************/
    /* STARTS HERE                      */
    /************************************/
 
    private CreateObject(change): DataObject {
        if (!('objid' in change) || isNaN(change['objid'])){
            change['objid'] = null;
        }

        let _table = change['table'];
        let _objid = change['objid'];

        switch(_table){
            case 'SESSION': return new Session(_objid, this);
            case 'FCM': return new FCM(_objid, this);
            case 'ADDRESS': return new Address(_objid, this);
            case 'ASKWAITER': return new AskWaiter(_objid, this);
            case 'CASHCHANGE': return new CashChange(_objid, this);
            case 'AUDIT': return new Audit(_objid, this);
            case 'AUDITINFO': return new AuditInfo(_objid, this);
            case 'EXTRA': return new Extra(_objid, this);
            case 'EXTRAPRODUCTOPT': return new ExtraProductOption(_objid, this);
            case 'EXTRAPRODUCT': return new ExtraProduct(_objid, this);
            case 'EXTRATABLE': return new ExtraQrCode(_objid, this);
            case 'OFFER': return new Offer(_objid, this);
            case 'OFFERPRODUCTOPT': return new OfferProductOption(_objid, this); 
            case 'OFFERPRODUCT': return new OfferProduct(_objid, this);
            case 'DISCOUNT': return new Discount(_objid, this);
            case 'PAYMENT': return new Payment(_objid, this);
            case 'STRIPE': return new StripeAccount(_objid, this);
            case 'OFFERPERIOD': return new OfferPeriod(_objid, this);
            case 'EXTRAPERIOD': return new ExtraPeriod(_objid, this);
            case 'PLACE': return new Place(_objid, this);
            case 'PLACEAREA': return new PlaceArea(_objid, this);
            case 'PLACELINK': return new PlaceLink(_objid, this);
            case 'PLACEOPT': return new PlaceOption(_objid, this);
            case 'RASPPI': return new PlaceService(_objid, this);
            case 'RASPPICONNECT': return new ServiceConnect(_objid, this);
            case 'PRINTER': return new PrintDevice(_objid, this);
            case 'SCALE': return new ScaleDevice(_objid, this);
            case 'PRINTERPRODUCTOPT': return new PrintProductOption(_objid, this);
            case 'PRINTERPRODUCT': return new PrintProduct(_objid, this);
            case 'INVOICEBUSINESS': return new Business(_objid, this);
            case 'PAYACCOUNT': return new PayAccount(_objid, this);
            case 'ACCOUNTINVOICE': return new AccountInvoice(_objid, this);
            case 'PRODUCT': return new Product(_objid, this);
            case 'CATEGORY': return new ProductCategory(_objid, this);
            case 'CATEGORYDEP': return new ProductDepend(_objid, this);
            case 'PRODUCTOPT': return new ProductOption(_objid, this);
            case 'PRESELECT': return new ProductSelect(_objid, this);
            case 'PRESELECTOPT': return new SelectOption(_objid, this);
            case 'FAMILY': return new Family(_objid, this);
            case 'FAMILYPERIOD': return new FamilyPeriod(_objid, this);
            case 'FAMILYPRODUCT': return new FamilyProduct(_objid, this);
            case 'QRCODE': return new QrCode(_objid, this);
            case 'TICKET': return new Ticket(_objid, this);
            case 'TICKETCHANGE': return new TicketChange(_objid, this);
            case 'TICKETBAI': return new TicketBAI(_objid, this);
            case 'TICKETSII': return new TicketSII(_objid, this);
            case 'TICKETINVOICE': return new TicketInvoice(_objid, this);
            case 'TICKETEXTRA': return new TicketExtra(_objid, this);
            case 'TICKETOFFER': return new TicketOffer(_objid, this);
            case 'TICKETOPTION': return new TicketOption(_objid, this);
            case 'TICKETPRODUCT': return new TicketProduct(_objid, this);
            case 'TICKETDISCOUNT': return new TicketDiscount(_objid, this);
            case 'USER': return new User(_objid, this);
            case 'USERADDRESS': return new UserAddress(_objid, this);
            case 'STAFF': return new Waiter(_objid, this);
            case 'DRAWITEM': return new DrawItem(_objid, this);
        }

        console.error("[DATA] Could not create object '" + _table + "@" + _objid + "'");
        return null;
    }

    private RecoverObject(change) : DataObject {
        let _entrynfo = change['entrynfo'];

        let _ddbbok = ('objid' in _entrynfo);
        let _ddbbko = ('_uuid' in _entrynfo);

        if (_ddbbok || _ddbbko){
            let _update: DataObject = this.CreateObject(_entrynfo);
            if (_update && _ddbbko){
                _update._uuid = _entrynfo['_uuid'];     // recover the object uuid
            }

            // add the object to its related objects
            if (_update){
                
                // check that all related objects are found
                for (let _field in change['requires']){
                    if (change['requires'][_field].intofrom == null){
                        continue;   // do not include into this parent
                    }

                    let _reqinfo = change['requires'][_field].relation;

                    _reqinfo['objid'] = _reqinfo['objid'] && !isNaN(_reqinfo['objid']) ? _reqinfo['objid'] : null;
                    _reqinfo['_uuid'] = _reqinfo['_uuid'] || null;

                    let _reqobjt = this.storage.GetByRef(_reqinfo['table'], _reqinfo['objid'], _reqinfo['_uuid']) as DataObject;
                    if (_reqobjt){ 
                        change['computed'].push({
                            info: change['requires'][_field].intofrom,
                            into: _reqobjt,
                            from: _update
                        });
                    }
                    else {
                        return null;    // do not return object if required items are not available
                    }
                }

                // make the relations from --> into
                for(let _relation of change['computed']){
                    if (_relation.info.to){
                        _relation.into.AddChild(_relation.info.to, _relation.from, _relation.info.by);
                    }
                }
            }

            return _update;                      
        }

        console.error("[DATA] Could not recover object: ", change);
        return null;    
    }

    private _get_session_uuid(change){
        return change['id'];
    }

    private _get_product_uuid(change){
        // if _uuid provided then is an update of an inserted item
        if (change['_uuid']){
            return change['_uuid'];
        }

        // if to be deleted, then update the resolved item
        if (change['status'] == 'DE'){
            return change['objid'];
        }

        // resolve from catalog, or else from resolved
        return change['ctgref'] || change['objid'];
    }

    private _getuuid(change){
        switch(change['table']){
            case 'SESSION':
                return this._get_session_uuid(change);
            case 'PRODUCT':
                return this._get_product_uuid(change);
        }

        return change['_uuid'];;
    }

    constructor(private lang: languageService, private toast: toastService, private view: viewService, private store: storeService, public platform: platformService, private download: downloadService, private logs: logsService, private sync: syncService){
        this._subscriptions.push(
            this.sync.OnExpired.subscribe(
            data => {
                this.Stop().then(
                data => {
                    this.sync.Release();
                });
            })
        );

        this._subscriptions.push(   // subscribe for changes awaiting to be sent
            this.sync.OnReload.subscribe(
            change => {
                change['computed'] = [];

                let _change = change['entrynfo'];
                let _update = this.storage.GetByRef(_change['table'], _change['objid'], _change['_uuid']) as DataObject;
                if (!_update){
                    _update = this.RecoverObject(change);
                    if (_update){
                        this.AddObject(_update);
                    }
                }

                if (_update){   // call to the update method of the target object
                    _update.OnChange(_change);

                    // make the relations from <-- into (after the OnChange)
                    for(let _relation of change['computed']){
                        if (_relation.info.by){
                            _update.SetChild(_relation.info.by, _relation.into);
                        }
                    }
                }

                // clear computed to avoid circular json
                if ('computed' in change){
                    delete change['computed'];  
                }
            })
        );
        
        this._subscriptions.push(   // subscribe to changes received from server
            this.sync.OnChange.subscribe(
            change => { 
                let _update = this.storage.GetByRef(change['table'], change['objid'], this._getuuid(change));
                if (!_update){  // include the object into the data model (if it doesn't exist)
                    _update = this.CreateObject(change);
                    if (_update){
                        this.AddObject(_update);
                    }
                }

                if (_update){   // call to the update method of the target object
                    _update.OnChange(change);
                }
            })        
        );
    }

    async ngOnDestroy(){
        if (this._reports){
            this._reports.OnDestroy();
        }

        for(let _subscription of this._subscriptions){
            _subscription.unsubscribe();
        }

        if (this._user_subscription){
            this._user_subscription.unsubscribe();
            this._user_subscription = null;
        }

        if (this._session){
            this.DoLogout(false);
        }
    }

    /************************************/
    /* VOLATILE STORAGES                */
    /************************************/

    CreateStorage() : Storage {
        return new Storage();
    }

    ReleaseStorage(_storage: Storage){
        _storage.Clear();
    }

    /************************************/
    /* STORED INSTANCES                 */
    /************************************/
   
    private _storage: Storage = null;
    get storage() {
        if (!this._storage){
            this._storage = new Storage();
        }
        return this._storage;
    }

    AddObject(dataobject: BaseObject) : boolean {
        return this.storage.AddObject(dataobject);
    }

    DelObject(dataobject: BaseObject){
        this.storage.DelObject(dataobject);
    }

    SetObject(dataobject: BaseObject){
        this.storage.SetObject(dataobject);
    }

    GetObject(dataobject: BaseObject) : BaseObject {
        return this.storage.GetObject(dataobject);
    }

    Resolve(dataobject: BaseObject){
        this.storage.Resolve(dataobject);
    }

    Replace(dataobject: BaseObject, uuid: string){
        this.storage.Replace(dataobject, uuid);
    }

    Clear(){
        this.storage.Clear();
        this.logs.Stop();
    }

    /************************************/
    /* START DATA MODEL                 */
    /************************************/

    private _Token(session, device){
        return '_' + new CRC32(session + device).value.toString(16);
    }

    private get Token(){
        return this._Token(this.sync.Session.session, this.sync.Session.device);
    }

    private Start(session, access){
        return new Promise((resolve) => {
            this._session = new Session(null, this, session);

            let _subscription = this._session.OnUpdated.subscribe(
            data => {
                _subscription.unsubscribe();

                // wait for refresh to be completed                
                setTimeout(async () => {
                    if (this._session){
                        if (access == 'LOGIN'){
                            await this.SetUser(this._session.user);
                            if (this.user){
                                this.sync.SetToken(this._session.id, this.Token);   
                                this.view.View = 'USER'; 
                            }
                        }
                        
                        if (access == 'GUEST'){
                            await this.SetTable(this._session.qrcode);
                            if (this.qrcode){
                                this.sync.SetToken(this._session.id, this.Token);
                                this.view.View = 'PLACE';
                            }
                        }

                        if (access == 'DEVRY') {
                            if (!this._session.user){   // this is a guest
                                this._session.user = new User(null, this, { volatile: true });
                            }

                            await this.SetUser(this._session.user);
                            await this.sync.SetToken(this._session.id, this.Token);   
                            this.view.View = 'LOGIN'; 
                        }
                    }

                    resolve(!!this._session);    
                }, 0);
            });

            if (this.AddObject(this._session)){
                this.sync.Start(session);
            }
            else {
                console.error("[ERROR] Could not initialize data model")
            }    
        })
    }

    private async Stop(){
        if (this._session){
            this._session.DoUpdate();
        }

        this._session = null;

        let _subscription = this.sync.OnReleased.subscribe(
        data => {
            _subscription.unsubscribe();
            window.location.reload();
        });
        
        await this.sync.Stop();

        await this.SetPlace(null);
        await this.SetUser(null);
        await this.SetTable(null);

        this.Clear();    
    }

    /************************************/
    /* SERVER REQUESTS                  */
    /************************************/

    private _doFetchhUrl = 'model/objidrequest.php';

    get OnRefreshCompleted(){
        return this.sync.OnRefreshCompleted;
    }

    DoRequest(url, params = null, post = null, showtoast = true){
        return this.sync.DoRequest(url, params, post, showtoast);
    }

    FetchByObjid(table, objid){
        return this.sync.DoRequest(this._doFetchhUrl, { table: table, objid: objid }, null, false);
    }

    /******************************/
    /* SERVER DATA DOWNLOAD       */
    /******************************/
    
    FetchFile(url){
        return new Promise(async(resolve) => {
            this.download.file(url)
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                resolve(null);
            });
        });
    }

    Download(url, filename, headers, download=true, target=null){
        console.info("[DOWNLOAD] url: '" + url + "'");

        return new Promise(async (resolve) => {
            this.toast.ShowWait();
            let data = await this.download.file(url);
            this.toast.HideWait();

            if (data){
                let file =  new Blob([data], { type: headers[ 'Content-Type' ] });
                let link = document.createElement("a");

                link.href = URL.createObjectURL(file);
                console.info("[DOWNLOAD] href: '" + link.href + "'");

                if (!download){     // open file in new page (if target = '_blank')
                    if (target) {
                        link.target = target;
                    }
                }
                else {          // download the file
                    link.download = filename;
                }

                document.body.appendChild(link);
                link.click();
  
                setTimeout(() => {
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href)
                }, 60000);  // wait 1 minute

                resolve(true)
            }
        });
    }
    
    /************************************/
    /* PROCESS SERVER UPDATES           */
    /************************************/

    private _changes = [];

    private _CheckAvailable(relation){
        let _relationstr = JSON.stringify(relation);
        return this._changes.concat(this.sync.Changes).some(
        (_commit) => {
            return (_relationstr == JSON.stringify(_commit.relation));
        });
    }

    private _CheckRequires(requires){
        for(let _dependency in requires){
            let _relation = requires[_dependency].relation;
            if (_relation){
                let _stored = this._storage.GetByRef(_relation['table'], ('objid' in _relation) ? _relation['objid'] : null, ('_uuid' in _relation) ? _relation['_uuid'] : null);
                if (!_stored) {
                    console.error("ERROR: Unmet dependency on commit (not in storage)", _relation);
                    return false;   // not in storage: this should not happen
                }
                
                if ((_stored.ToInsert) && (!this._CheckAvailable(_relation))){
                    console.error("ERROR: Unmet dependency on commit (not in commit)", _relation);
                    return false;   // depency not available and not ready to commit
                }
            }
        }

        return true;
    }

    private _transacstat = 'CLOSED';    // 'CLOSED' / 'OPEN' / 'ERROR'
    private _transaction = [];

    private _transactionCompleted = new Subject<any> ();
    private OnTransactionCompleted = this._transactionCompleted.asObservable();

    // will return a transaction object in where the items must be added
    async BeginTransaction(){
        let _transaction = new Transaction(this);

        if (this._transacstat == 'CLOSED'){
            this._transacstat = 'OPEN';
            return _transaction;
        }
        else {
            console.info("[COMMIT]: waiting for previous transaction");
            
            let _promise = new Promise <Transaction> ((resolve) => {
                let _subscription = this.OnTransactionCompleted.subscribe(
                async data => {
                    _subscription.unsubscribe();

                    console.info("[COMMIT]: transaction has been completed");
                    await this.BeginTransaction();
                    resolve(_transaction);
                })
            });

            return await _promise;
        }
    }

    // this will be called from the transaction instance
    async CommitTransaction(_transaction, force: boolean = false){
        if (this._transacstat != 'CLOSED'){
            let _rollback = (this._transacstat == 'ERROR');

            if (!_rollback){
                for(let _commit of this._transaction){
                    this._changes.push(_commit);
                }
                await this.CommitChanges(force);    
            }

            this._transaction = [];

            this._transacstat = 'CLOSED';
            this._transactionCompleted.next();

            return !_rollback;
        }

        console.error("ERROR: transaction is already closed");
        return false;
    }

    PushChange(commits){
        let _inTransaction = (this._transacstat != 'CLOSED')
        for (let _commit of commits){
            if (!this._CheckRequires(_commit.requires)){
                if (_inTransaction){
                    this._transacstat = 'ERROR';
                }

                console.error("ERROR: dependecies not met for change: ", _commit);
                return false;       // dependencies are not resolved within the changeset
            }

            if (_inTransaction){
                this._transaction.push(_commit);
            }
            else {
                this._changes.push(_commit);
            }
        }

        return true;
    }

    private _logchanges = false;
    private _LogChange(_change) {
        if (this._logchanges){
            let _entrynfo = _change.entrynfo;

            let _newentry = !(('objid' in _entrynfo) && _entrynfo['objid']);
            if (_newentry){
                console.log("[COMMIT] INSERT - '" + _entrynfo['table'] + "'");
            }
            else {
                console.log("[COMMIT] UPDATE - '" + _entrynfo['table'] + " (with objid [" + _entrynfo['objid'] + "]'");
            }
    
            for (let _field in _entrynfo){
                if (['table', 'objid'].includes(_field)){
                    continue;
                }
    
                console.log(" - " + _field + ": " + _entrynfo[_field]);
            }
        }
    }

    async CommitChanges(force: boolean = false){
        let _changes = [];
        for (let _change of this._changes){
            let _commit = await _change;        // resolve asynchronous calls
            _changes.push(_commit);             // push the change
        }

        if (_changes.length > 0){
            if (GenericUtils.VerboseLogging()){
                for (let _change of _changes){
                    this._LogChange(_change);
                }    
            }

            await this.sync.CommitChanges(_changes, force);
        }

        this._changes = [];     // changes are now in sync service           
    }

    /************************************/
    /* PUBLIC METHODS                   */
    /************************************/

    private _doLoginUrl = 'login/login.php';
    private _doStoredUrl = 'login/stored.php';
    private _doRegisterUrl = 'login/register.php';
    private _doRecoverUrl = 'login/recover.php';
    private _doActivateUrl = 'login/resend.php';
    private _doPasswordUrl = 'login/password.php';
    private _doAccessUrl = 'guest/access.php'
    private _doLogoutUrl = 'login/logout.php';
    private _freeUserUrl = 'login/available.php';
    private _doSecureWrUrl = 'protect/add.php';
    private _doSecureRdUrl = 'protect/get.php';
    private _newTicketUrl = 'device/newticket.php';
    private _getTicketUrl = 'device/getticket.php';
    private _prvTicketUrl = 'device/prvticket.php';

    private _passwordEncode(_encode0, singlecrc = false){
        let _encode1 = new CRC32(_encode0).value.toString(16);
        let _encode2 = new CRC32(_encode1).value.toString(16);

        return singlecrc ? _encode1 : _encode2;
    }

    /******************************/
    /* LOGIN METHODS              */
    /******************************/
 
    DoLogin(username, password, remember, singlecrc){
        let _password = this._passwordEncode(password, singlecrc);
        return new Promise((resolve, reject) => {
            this.sync.DoRequest(this._doLoginUrl, null, { username: username, password: _password })
            .then(async data => {
                let _success = (data['errorcode'] == 0);
                if (_success){
                    await this.Start(data['session'], (this.view.Access == 'DEVRY')? 'DEVRY': 'LOGIN');
                    if (remember){
                        this.store.SetStoredLogin({
                            deviceid: this.sync.Session.device,
                            session: this.sync.Session.session,
                            username: username
                        });
                    }
                }

                resolve(_success);
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doLoginUrl + "'");
                reject(error);
            });        
        });
    }

    async DoToken(_token){
        return new Promise <boolean> (async (resolve) => {
            await this.store.SetToken(_token);
            if (_token){
                let _data = await this.store.RecoverToken();
                if (!_data){
                    resolve(false);
                }
                else {
                    if (this.view.Access == 'DEVRY'){
                        this.Start(_data['session'], 'DEVRY').then(
                        data => {
                            if (_data.orderid && _data.placeid){
                                let _ticket = new Ticket(_data.orderid, this);
                                if (_ticket){
                                    this.SetTicket(_ticket);
                                }

                                let _place = new Place(_data.placeid, this);
                                if (_place){
                                    this.SetPlace(_place);
                                }
                            }

                            resolve(true);
                        });
                    }
                    else {
                        this.Start(_data['session'], 'LOGIN').then(
                        data => {
                            if (_data.placeid){
                                let _place = this.storage.GetByRef('PLACE', _data.placeid, null) as Place;
                                if (_place){
                                    this.SetPlace(_place);
                                }
                            }
                            
                            resolve(true)
                        });
                    }
                }
            }
            else {
                resolve(false);
            }
        });
    }

    async DoStored(){
        return new Promise((resolve) => {
            this.store.GetStoredLogin(this.sync.Session.device).then(
            async data => {
                if (data){
                    let _token_succeded = await this.DoToken(this._Token(data['session'], data['deviceid']));
                    if (_token_succeded){
                        resolve(true);  // token information available on the device (server connection not required)
                    }
                    else {
                        this.sync.DoRequest(this._doStoredUrl, { device: data['deviceid'], session: data['session'], username: data['username'] }).then(
                        async data => {
                            let _success = (data['errorcode'] == 0);
                            if (_success){
                                await this.Start(data['session'], (this.view.Access == 'DEVRY')? 'DEVRY': 'LOGIN');
                            }
                            else {
                                console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doStoredUrl + "'");
                                this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                            }
    
                            resolve(_success);
                        });        
                    }

                }
                else {
                    resolve(false);
                }
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doStoredUrl + "'");
                resolve(false);
            });
        });
    }

    DoRecover(username){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._doRecoverUrl, { username: username })
            .then(data => {
                resolve(true);
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doRecoverUrl + "'");
                resolve(false);
            });        
        });
    }

    DoRegister(username, password){
        let _password = this._passwordEncode(password);
        return new Promise((resolve) => {
            this.sync.DoRequest(this._doRegisterUrl, null, { username: username, password: _password })
            .then(async data => {
                let _success = (data['errorcode'] == 0);
                if (_success){
                    await this.Start(data['session'], (this.view.Access == 'DEVRY')? 'DEVRY': 'LOGIN');
                }
                else {
                    console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doRegisterUrl + "'");
                    this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                }

                resolve(_success);
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doRegisterUrl + "'");
                resolve(false);
            }); 
        });
    }

    DoLogout(expired = false){
        return new Promise(async (resolve) => {
            if (this._session == null){
                resolve(false);     // already released
            }
            else {
                await this.Stop();

                this.sync.DoRequest(this._doLogoutUrl, null, null)
                .then(data => {      
                    this.sync.Release();
    
                    if (!expired){  // do not remove stored information if expired due to inactivity
                        this.store.DelStoredLogin().then(
                        data => {
                            resolve(true);
                        });        
                    }
                    else {  // try to perform stored login to create new session
                        this.DoStored().then(
                        data => {
                            resolve(true);
                        })        
                    }
                }, error => {
                    console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doLogoutUrl + "'");
                    resolve(false);
                });        
            }
        });
    }

    DoPassword(oldpassword, newpassword, singlecrc){
        let _params = {
            old: this._passwordEncode(oldpassword, singlecrc),
            new: this._passwordEncode(newpassword)
        };

        return new Promise((resolve) => {
            this.sync.DoRequest(this._doPasswordUrl, _params, null)
            .then(data => {
                if (data['errorcode'] == 0){
                    this.store.DelStoredLogin().then(
                    data => {
                        resolve(true);
                    });            
                }
                else {
                    resolve(false);
                }
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doPasswordUrl + "'");
                resolve(false);
            });         
        });
    }

    private _TokenAccess(_token){
        return new Promise <boolean> (async (resolve) => {
            await this.store.SetToken(_token);
            this.store.RecoverToken()
            .then(async data => {
                let success = (data && data['session']);
                if (success) {
                    await this.Start(data['session'], 'GUEST');
                }
                resolve(!!success)
            });
        });
    }

    private _TableAccess(_table, _auth){
        return new Promise <boolean> (async (resolve) => {
            this.sync.DoRequest(this._doAccessUrl, null, { auth: _auth, table: _table })
            .then(async data => {
                let _success = (data['errorcode'] == 0);
                if (_success){
                    await this.Start(data['session'], 'GUEST');
                }
    
                resolve(_success);
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doAccessUrl + "'");
                resolve(false);
            });             
        });
    }

    DoAccess(_table, _auth, _token){
        return new Promise(async (resolve) => {
            let _success = false;
            
            if (!_success) {    // first try token access (if token is provided)
                if (_token){
                    _success = await this._TokenAccess(_token);
                }
            }

            if (!_success){     // else try table access (will create a new session)
                _success = await this._TableAccess(_table, _auth);
            }

            resolve(_success)
        });
    }

    DoActivate(username){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._doActivateUrl, { username: username }, null)
            .then(data => {
                resolve(data['errorcode'] == 0);
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doActivateUrl + "'");
                resolve(false);
            });         
        });
    }

    Available(username){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._freeUserUrl, { username: username })
            .then(data => {
                resolve(data);
            }, error => {
                resolve(false);
            }); 
        });
    }

    /************************************/
    /* TICKET PRE-CREATON               */
    /************************************/

    CreateTicket(ticket){
        return new Promise((resolve) => {
            if (this.view.IsOnline){
                let _placeid = ticket.place ? ticket.place.objid : 0;
                let _invceid = ticket.invoice ? ticket.invoice.objid : 0;
    
                this.sync.DoRequest(this._newTicketUrl, { serial: this.serial, place: _placeid, invoice: _invceid }, null)
                .then(data => {
                    let _success = (data['errorcode'] == 0);
                    if (_success){
                        resolve(data['ticket']);
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._newTicketUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        resolve(null);
                    }
                }, error => {
                    console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._newTicketUrl + "'");
                    resolve(null);
                });                             
            }
            else {
                resolve(null);  // offline
            }
        });
    }

    async NextInvoice(type: "T" | "R" | "F" | "A" | "C"){
        if (this.view.Access == 'LOGIN'){   // not a guest
            return await this.store.GetLastTicket(this.place, this.serial, type);
        }

        // guest access: recover ticket from server
        let _promise = new Promise((resolve) => {
            this.sync.DoRequest(this._getTicketUrl, { serial: this.serial, place: this.place.objid }, null)
            .then(data => {
                let _success = (data['errorcode'] == 0);
                if (_success){
                    resolve(type + ("00000000" + (parseInt(data['last'][type]) + 1)).slice(-8));
                }
                else {
                    console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._getTicketUrl + "'");
                    this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                    resolve(null);
                }
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._getTicketUrl + "'");
                resolve(null);
            });             
        });

        return await _promise;
    }

    async GetLastInvoice(){
        if (this.view.Access == 'LOGIN'){   // not a guest
            return await this.store.GetLastTicket(this.place, this.serial, null);
        }

        // guest access: recover ticket from server
        let _promise = new Promise((resolve) => {
            this.sync.DoRequest(this._prvTicketUrl, { serial: this.serial, place: this.place.objid }, null)
            .then(data => {
                let _success = (data['errorcode'] == 0);
                if (_success){
                    resolve(data['ticket']);
                }
                else {
                    console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._prvTicketUrl + "'");
                    this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                    resolve(null);
                }                
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._prvTicketUrl + "'");
                resolve(null);
            });             
        });

        return await _promise;
    }

    async SetLastInvoice(_change){
        if (_change.reason == 'C'){
            return;     // do not store cancelations
        }

        let _dd = ("00" + _change.created.getDate()).slice(-2);
        let _mm = ("00" + (_change.created.getMonth()+1)).slice(-2);
        let _yy = _change.created.getFullYear();

        let _ltserved = {
            last: {
                created: _dd + '-' + _mm + '-' + _yy,
                series: _change.series,
                invoice: _change.invoice,
                tbai: _change.ticketbai ? _change.ticketbai.sign : null
            }
        };

        if (_change.reason != 'C'){
            _ltserved[_change.invoice[0]] = parseInt(_change.invoice.slice(1));
        }

        return await this.store.SetLastTicket(this.place, this.serial, _ltserved);
    }

    /************************************/
    /* SECURED DATA ACCESS              */
    /************************************/

    async SetSessionSecured(key, value){
        return await this.store.AddSessionData(key, value);
    }

    async GetSessionSecured(key){
        return await this.store.GetSessionData(key);
    }

    async SetServerSecured(target, entry, field, value){
        let _post = {
            'target': target,
            'entry': entry, 
            'field': field,
            'value': value
        };

        return new Promise((resolve) => {
            this.sync.DoRequest(this._doSecureWrUrl, null, _post)
            .then(async (data) => {
                if (data['errorcode'] == 0){
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doSecureWrUrl + "'");
                resolve(false);
            });
        });        
    }

    async GetServerSecured(target, entry, field){
        let _getparams = {
            'target': target,
            'entry': entry, 
            'field': field
        };

        return new Promise((resolve) => {
            this.sync.DoRequest(this._doSecureRdUrl, _getparams)
            .then(async (data) => {
                if (data['errorcode'] == 0){
                    resolve(data['value']);
                }
                else {
                    resolve(null);
                }
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doSecureRdUrl + "'");
                resolve(false);
            });
        });            
    }


    /************************************/
    /* SERVER TICKETS ACCESS            */
    /************************************/

    async LoadPlaceTickets(place, ini, end, updated){
        let _tickets = [];

        ini = ini ? ini : new Date(0);  // default date is first date
        end = end ? end : new Date();   // default date is current date

        let _tmini = ini.getTime();
        let _tmend = end.getTime();

        // if period is less that 5 days load tickets from place
        if (_tmend - _tmini < (5 * 24 * 3600 * 1000)){

            for(let _ticket of place.tickets){
                let _tmstamp = updated ? _ticket.updated.getTime(): _ticket.created.getTime();
                if ((_tmstamp < _tmini) || (_tmstamp > _tmend)){
                    continue;   // not in provided period
                }

                _tickets.push(_ticket);
            }
        }

        // otherwise, request the tickets form server (older tickets)
        else {
            let _servertickets = null;
            if (updated){
                _servertickets = await this.Reports.LoadUpdatedTickets(place, ini, end);
            }
            else {
                _servertickets = await this.Reports.LoadCreatedTickets(place, ini, end);
            }

            for(let _ticket of _servertickets){
                let _tmstamp = updated ? _ticket.updated.getTime(): _ticket.created.getTime();
                if ((_tmstamp < _tmini) || (_tmstamp > _tmend)){
                    continue;   // not in provided period
                }

                _tickets.push(_ticket);
            }
        }

        // do not include cancelled tickets
        let _place_tickets = [];
        for(let _ticket of _tickets){
            if (!_ticket.IsCancelled){
                _place_tickets.push(_ticket);
            }
        }

        return _place_tickets;
    }

    /************************************/
    /* LOCAL ACTIONS LOGGER             */
    /************************************/

    async GetActionsLog(place, inidate, enddate){
        return await this.store.GetActionsLog(place, inidate, enddate);
    }

    LogTicketAction(ticket: Ticket){
        this.store.LogTicketAction(ticket.place, ticket);
    }
} 