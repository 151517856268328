import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { Ticket } from './ticket';
import { AccountInvoice } from './accountinvoice';
import { Business } from './business';

/************************************/
/* ACCOUNT TICKET LIST              */
/************************************/

export class TicketList {
    private _set = new Set <Ticket> ();
    
    private _find(ticket: Ticket) : Ticket{
        if (this._set.has(ticket)){
            return ticket;
        }
        for (let _ticket of this._set){
            if ((ticket.objid != null) && (ticket.objid == _ticket.objid)) {
                return _ticket
            }
        }

        return null;        // not stored
    }

    add(ticket: Ticket){
        let _ticket = this._find(ticket);
        if (_ticket){
            this._set.delete(_ticket);
        }

        this._set.add(ticket);
    }

    del(ticket: Ticket){
        let _ticket = this._find(ticket);
        if (_ticket){
            this._set.delete(_ticket);
        }
    }
    
    [Symbol.iterator]() {
        const tickets = Array.from(this._set);
        let index = 0;

        return {
            next: () => {
                if (index < tickets.length) {
                    return {
                        value: tickets[index++],
                        done: false,
                    };
                } 
                else {
                    return {
                        done: true,
                    };
                }
            },
        };
    }
}

/************************************/
/* PAYACCOUNT                       */
/************************************/

export interface _PayAccount {
    place: number,
    name: string;
    status: string;
    isbusiness: boolean;
    client: number;
};

interface _PayAccountData extends _PayAccount {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class PayAccountData extends DataObject {
    protected _payaccount: _PayAccountData = {
        place: null,
        name: null,
        status: null,
        isbusiness: null,
        client: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._payaccount.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._payaccount.created;
    }

    get status(): string {
        return this._payaccount.status;
    }

    set status(value: string) {
        if (this.patchValue(this._payaccount, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get name(): string {
        return this._payaccount.name;
    }

    set name(value: string) {
        if (this.patchValue(this._payaccount, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get isbusiness(): boolean {
        return this._payaccount.isbusiness;
    }

    set isbusiness(value: boolean) {
        if (this.patchValue(this._payaccount, 'isbusiness', value)){
            this.ToUpdate = true;
        }
    }

    get client(): Business {
        return this._children['client'] || null;
    }

    set client(value: Business ){
        if (this.SetChild('client', value, 'client')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddInvoice(child: AccountInvoice){
        this.AddChild('invoices', child, 'account');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get invoices() : Array <AccountInvoice> {
        return this._chldlist['invoices'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._payaccount.place,
            name: this._payaccount.name,
            status: this._payaccount.status,
            isbusiness:  this._payaccount.isbusiness ? '1':'0',
            client: this._payaccount.client
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'accounts', by: 'place' } },    // this[by -> 'place'][to -> 'accounts'] => this
            client: { item: this.client, relation_info: { to: null, by: 'client' } }        // no relation to this in this[by -> 'client']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.invoices){
            _children.push(_item);
        }
        
        if (this.client){
            _children.push(this.client);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_payaccount: _PayAccount){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._payaccount, 'place', _payaccount['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._payaccount, 'status', _payaccount['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._payaccount, 'name', _payaccount['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._payaccount, 'isbusiness', _payaccount['isbusiness']) || _toUpdate;
        _toUpdate = this.patchValue(this._payaccount, 'client', _payaccount['client']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_payaccount: _PayAccount){
        this.patchValue(this._payaccount, 'created', _payaccount['created']);
        
        if (this._patchData(_payaccount)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._payaccount;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _PayAccountData {
        let _payaccount: _PayAccountData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            place: info['place'] ? parseInt(info['place']) : null,
            status: info['status'],
            name: info['name'],
            isbusiness: (info['isbusiness'] != '0'),
            client: info['client'] ? parseInt(info['client']) : null
        };
        return _payaccount;
    }

    private DoPatchValues(_payaccount: _PayAccount){
        this._patchData(_payaccount);

        if(_payaccount['place']){      // update children: 'place'
            let _objid = _payaccount['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }  
        else {
            this.SetChild('place', null, 'place');
        }

        if(_payaccount['client']){      // update children: 'client'
            let _objid = _payaccount['client'].toString();
            this.SetChild('client', new Business(_objid, this.data, this._objoptions), 'client')
        }  
        else {
            this.SetChild('client', null, 'client');
        }        
    }

    protected _OnUpdate(info){
        let _payaccount = this._ddbb(info);
        this.patchValue(this._payaccount, 'objid', _payaccount['objid']);
        this.patchValue(this._payaccount, 'created', _payaccount['created']);
        this.DoPatchValues(_payaccount);

        if (info['invoice']){   // update children: 'invoice'
            this.SetChildren <AccountInvoice> (info['invoice'], 'invoices', AccountInvoice, 'account');
        }
    }
}

export class PayAccount extends PayAccountData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PAYACCOUNT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : PayAccount {
        return this._Copy(store) as PayAccount;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get business(): string {
        if (this.client){
            return this.client.name;
        }
        return null;
    }

    get taxid(): string {
        if (this.client){
            return this.client.taxid;
        }
        return null;        
    }

    get address(): string {
        if (this.client){
            return this.client.address;
        }
        return null;  
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        if (!this.status){
            return false;
        }
        
        return (this.status != 'DE');
    }

    get IsBlocked(){
        return (this.status == 'BL');
    }
}

