import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { Printer, PrintOptions } from '@ionic-native/printer/ngx';

import { AppConstants } from '@app/app.constants';
import { platformService } from '@app/modules/common/platform';
import { httpService } from '@app/modules/common/http';
import { languageService } from '@app/modules/common/language';
import { toastService } from '@app/modules/common/toast';
import { deviceService } from '@app/modules/sockets';
import { syncService } from '@app/modules/sync';

function __closePrint (iframe) {
    setTimeout(() => {
        document.body.removeChild(iframe);
    }, 1000);
}

@Injectable()
export class printService implements OnDestroy {
    private _doExportUrl = 'printer/service/export.php';
    private _doPrintUrl = 'printer/service/doprint.php';
    
    private _printQueue = [];
    private _online_subscription = null;
        
    constructor(private lang: languageService, private printer: Printer, private http: httpService, private devsvc: deviceService, private sync: syncService, private toast: toastService, private platform: platformService) {        
        let _styles = [
            'ticket-print.css',
            'menu-print.css',

            'report-clients.css',
            'report-income.css',
            'report-monthly.css',
            'report-product.css'
        ];

        console.info("[PRINT] pre-loading stylesheets..")
        for(let _style of _styles){
            fetch('assets/styles/' + _style).then(
            response => {
                if (response.status == 200){
                    console.info("[PRINT] Loaded stylesheet '" + _style + "'");
                }
                else {
                    console.error("[PRINT] Loaded stylesheet '" + _style + "' (status: " + response.status + ")");
                }
            });
        }

        this._online_subscription = this.http.OnOnline.subscribe(
        online => {
            if (online){
                this._processQueued();
            }
        })
    }
    
    ngOnDestroy() {
        this._WSRelease();

        if (this._online_subscription){
            this._online_subscription.unsubscribe();
            this._online_subscription = null;
        }
    }

    private setPrint() {
        let _document = this._frame.contentDocument || this._frame.contentWindow.document;
        if (_document){
            let _body = _document.querySelector("body");
            if (_body){
                _body.classList.add("content")
            }
        }

        (async () => {  // wait for iframe content
            let _iframe_promise = new Promise((resolve) => {
                const checkIframe = () => {
                    if (this._frame.contentWindow && this._frame.contentDocument.readyState === 'complete') {
                        resolve(true);
                    } 
                    else {
                        setTimeout(checkIframe, 250);
                    }
                };

                checkIframe();
            });

            await _iframe_promise;
            
            this._frame.contentWindow.focus();
            this._frame.contentWindow.onbeforeunload = () => __closePrint(this._frame);
            this._frame.contentWindow.onafterprint = () => __closePrint(this._frame);
            this._frame.contentWindow.print();    
        })();
    }   

    private async htmlHeight(html){
        return new Promise((resolve) => {
            var ifrm = document.createElement("iframe");
            ifrm.onload = () => {
                if (!ifrm.srcdoc){
                    return;
                }

                let _height = ifrm.contentWindow.document.body.scrollHeight;
                ifrm.parentNode.removeChild(ifrm);
                resolve(((_height/96) * 25.4) + 15);   // dompdf configured to 96dpi + 15mm extra
            };

            document.body.appendChild(ifrm);
            ifrm.srcdoc = html;    
        });
    }

    private async htmlStyleContent(title, elem, style, font, bold, margins, custom, links){
        if (!elem){
            console.warn("[TO HTML] Warning, no content provided!")
            return null;
        }
        
        let _html = '<!DOCTYPE html>'; 
        let _rstr = 'size: ' + margins[0] + 'mm 210mm portrait;';
       
        _html += '<html><head><meta http-equiv="Content-type" content="text/html; charset=utf-8" />\n';
        _html += '<title>' + title  + '</title>\n';

        for(let link of links){
            _html += link + "\n";
        }
        
        _html += '<style>\n';
        _html += '@page {\n'

        if (margins[0] != 0){
            _html += 'size: ' + margins[0] + 'mm 210mm portrait;\n';
            _html += 'width: '+ margins[0] + 'mm !important;\n';    
        }

        _html += 'margin-top: ' + margins[1] + "mm;\n";
        _html += 'margin-right: ' + margins[2] + "mm;\n";
        _html += 'margin-bottom: ' + margins[3] + "mm;\n";
        _html += 'margin-left: ' + margins[4] + "mm;\n";

        _html += 'max-height: 99%;\n';
        _html += 'max-width: 99%;\n';
        _html += '}\n'

        if (style) {
            let response = await fetch(style);
            if (response.status != 200) {
                console.error("Could not read style sheet @ '" + style + "' (server error: " + response.status + ")") 
            }
            else {
                let _text = await response.text();
                if (_text){
                    _html += _text + '\n';
                } 
            }
        }

        if (margins[0] != 0){
            _html += 'body.content {\n';
            _html += 'width: '+ margins[0] + 'mm !important;\n'; 
            _html += '}\n';
        }

        if (custom != null){    // apply custom style to body
            _html += 'body {\n';
            _html += custom;
            _html += '}\n';
        }

        if ((font != null) || (bold)){
            _html += '* {\n';

            if (font != null){    // overwirte the font-size value in css
                _html += 'font-size: ' + font + 'px !important;\n';
            }
            if (bold == true){    // overwirte the font-weight value in css
                _html += 'font-weight: bold !important;\n'
            }

            _html += '}\n';                
        }

        _html += '</style>';
        _html += '</head><body>' + elem.innerHTML + '</body></html>'
        
        let _hght = Number(await this.htmlHeight(_html)) + margins[1] + margins[3] + 20;
        let _fstr = 'size: ' + margins[0] + 'mm ' + Math.ceil(_hght) + 'mm portrait;';
        _html = _html.replace(_rstr, _fstr);    // set the final height into the html page style

        return _html;
    }
        
    private _OnDrawerNotify(){
        this.toast.ShowAlert('primary', this.lang.tr('@opening_drawer'), 2000);
    }

    /*****************************/
    /* CORDOVA PRINTING          */
    /*****************************/

    private _cordovaPrintPage (title, _html) {
        let options: PrintOptions = {
            name: title,
            duplex: false,
            orientation: 'portrait',
            monochrome: true
        }            

        this.printer.print(_html, options);
    }
    
    /*****************************/
    /* NATIVE PRINTING           */
    /*****************************/

    private _frame = null;    
    private _browserPrintPage (title, _html) {
        this._frame = document.createElement("iframe");
        this._frame.onload = this.setPrint.bind(this);
        this._frame.style.position = "fixed";
        this._frame.style.right = "0";
        this._frame.style.bottom = "0";
        this._frame.style.width = "0";
        this._frame.style.height = "0";
        this._frame.style.border = "0";
        
        this._frame.srcdoc = _html;
        
        document.body.appendChild(this._frame);
    }

    /*****************************/
    /* SERVER PRINTING           */
    /*****************************/

    private _connections = {
        name: null,
        host: null,
        port: null,
        place_subscription: null,

        Lconnection: {                      // local connection info (ConnectionInfo)
            connecting: null,               // wait for ongoing connecting operations
            connection: null,               // keep the local connection open
            waitretry: null,                // wait to retry openning the connection
            subscription: null              // listen to connection disconnect event (to retry)
        },                                  
        Rconnection: new Map <string, {     // remote connections (by connected service)
            connecting: any,                // wait for ongoing connecting operations
            connection: any,                // keep the local connection open
            waitretry: any,                 // wait to retry openning the connection
            subscription: any               // listen to connection disconnect event (to retry)
        }> ()    
    };

    private _CheckRelease(service){
        if (service){
            if (this._connections.name != service.name){
                return true;
            }

            if (this._connections.host != service.wshost){
                return true;
            }

            if (this._connections.port != service.wsport){
                return true;
            }

            return false;   // no changes
        }
        else {
            return true;
        }
    }

    private async _WSRelease(){
        let _lconnection = this._connections.Lconnection;
        if (_lconnection.subscription){
            _lconnection.subscription.unsubscribe();
            _lconnection.subscription = null;
        }

        if (_lconnection.connection){
            _lconnection.connection.Disconnect();
            _lconnection.connection = null;
            _lconnection.connecting = null;
        }

        for (let [service, _rconnection] of this._connections.Rconnection){
            if (_rconnection.subscription){
                _rconnection.subscription.unsubscribe();
                _rconnection.subscription = null;
            }
    
            if (_rconnection.connection){
                _rconnection.connection.Disconnect();
                _rconnection.connection = null;
                _rconnection.connecting = null;
            }    
        }

        this._connections.Rconnection.clear();

        if (this._connections.place_subscription){
            this._connections.place_subscription.unsubscribe();
            this._connections.place_subscription = null;
        }
    }

    private _WaitForSubject(subject){
        if(subject){
            return new Promise((resolve) => {
                let _subscription = subject.asObservable().subscribe(
                data => {
                    _subscription.unsubscribe();
                    setTimeout(() => {
                        resolve(true);  
                    }, 0);
                });    
            })
        }   

        return false;   // no subject
    }

    private async _WSConnect(place, service, islocal){
        let _host = null;
        let _port = null;
        let _name = null;

        if (service){
            this._connections.name = _name = service.name;
            this._connections.host = _host = service.wshost;
            this._connections.port = _port = service.wsport;    

            // refresh connections on place refresh
            if (this._connections.place_subscription == null){
                this._connections.place_subscription = place.OnTargetRefresh('PLACE').subscribe(
                data => {
                    if (this._CheckRelease(service)){
                        this._WSRelease();      // connection parameters have been updated
                    }
                });
            }
        }
        else {
            return null;
        }

        if (islocal){
            let _lconnection = this._connections.Lconnection;
            if (_lconnection.connecting){
                while(await this._WaitForSubject(_lconnection.connecting));    
            }

            if (!_lconnection.connection && !_lconnection.waitretry){    
                _lconnection.connecting = new Subject <any> ();
                _lconnection.connection = await this.devsvc.Connect(_host, _port, _name, true);
                
                // wait 5 seconds to retry the connection
                if (!_lconnection.connection){
                    _lconnection.waitretry = setTimeout(() => {
                        _lconnection.waitretry = null;
                    }, 5);  
                }

                // subscribe to external disconnect events
                if (_lconnection.connection && !_lconnection.subscription){
                    _lconnection.subscription = _lconnection.connection.OnDisconnected.subscribe(
                    data => {
                        _lconnection.connection = null;
                        _lconnection.subscription = null;
                    });        
                }

                _lconnection.connecting.next();
                _lconnection.connecting = null;    
            }

            if (!_lconnection.connection){
                console.warn("[PRINT] Cannot connect to service (" + service.name + ") - local connection: TRUE")
            }

            return _lconnection.connection;
        }
        else {
            let _rconnection = this._connections.Rconnection.get(service.name);
            if (!_rconnection){     // first connection to this service
                _rconnection = {
                    connecting: null,
                    connection: null,
                    waitretry: null,
                    subscription: null    
                };

                this._connections.Rconnection.set(service.name, _rconnection);
            }

            if (_rconnection.connecting){
                while (await this._WaitForSubject(_rconnection.connecting));    
            }
            
            if (!_rconnection.connection && !_rconnection.waitretry){
                _rconnection.connecting = new Subject <any> ();
                _rconnection.connection = await this.devsvc.Connect(_host, _port, _name, false);

                // wait 5 seconds to retry the connection
                if (!_rconnection.connection){
                    _rconnection.waitretry = setTimeout(() => {
                        _rconnection.waitretry = null;
                    }, 5);  
                }

                // subscribe to external disconnect events
                if (_rconnection.connection && !_rconnection.subscription){
                    _rconnection.subscription = _rconnection.connection.OnDisconnected.subscribe(
                    data => {
                        _rconnection.connection = null;
                        _rconnection.subscription = null;
                    });        
                }

                _rconnection.connecting.next();
                _rconnection.connecting = null;    
            }

            if (!_rconnection.connection){
                console.warn("[PRINT] Cannot connect to service (" + service.name + ") - local connection: FALSE")
            }

            return _rconnection.connection;
        }
    }

    private async _wsLocalSend(place, device, _html, _open){
        let _tmstamp = performance.now()
        let _promises = [];
        let _result = false;

        let _connection = await this._WSConnect(place, device.rasppi, true);
        if (_connection){
            if (_html){
                _promises.push(this.devsvc.doPrint(_connection, device.device, _html, device.copies));
            }
    
            if (_open){
                _promises.push(this.devsvc.doOpen(_connection, device.device))
            }
    
            let _values = await Promise.all(_promises);
            
            _result = _values.length > 0;
            for (let _value of _values){
                _result = _result && _value;
            }
        }

        if (_result){
            console.info("[PRINT] succesfully sent to print server (local WS send) in " + ((performance.now() - _tmstamp) / 1000).toFixed(3) + " seconds"); 
        }
        else {
            console.info("[PRINT] error sending to print server (local WS send) in " + ((performance.now() - _tmstamp) / 1000).toFixed(3) + " seconds"); 
        }

        return _result;
    }

    private async _wsRemoteSend(place, device, _html, _open){
        let _tmstamp = performance.now()
        let _promises = [];
        let _result = false;

        let _connection = await this._WSConnect(place, device.rasppi, false);
        if (_connection){
            if (_html){
                _promises.push(this.devsvc.doPrint(_connection, device.device, _html, device.copies));
            }
    
            if (_open){
                _promises.push(this.devsvc.doOpen(_connection, device.device))
            }
    
            let _values = await Promise.all(_promises);
            
            _result = _values.length > 0;
            for (let _value of _values){
                _result = _result && _value;
            }    
        }

        if (_result){
            console.info("[PRINT] succesfully sent to print server (remote send) in " + ((performance.now() - _tmstamp) / 1000).toFixed(3) + " seconds"); 
        }
        else {
            console.info("[PRINT] error sending to print server (remote send) in " + ((performance.now() - _tmstamp) / 1000).toFixed(3) + " seconds");
        }

        return _result;
    }

    private async _raspberrySend(_getparams, _html){
        return new Promise <boolean> ((resolve) => {
            let _headers = {
                'Accept': 'text/html',
                'Content-Type': 'application/json'  // 'text/html'
            };

            this.sync.DoRequest(this._doPrintUrl, _getparams, _html, false, _headers, null, httpService.serializer.utf8)
            .then(data => {
                let success = (data['errorcode'] == 0); 
                if (success){
                    console.info("[PRINT] succesfully stored pdf backup on server");                    
                }
                else {
                    console.info("[PRINT] print server returned errorcode [" + data['errorcode'] + "]");
                }

                resolve(success); 
            }, error => {
                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doPrintUrl + "'");
                resolve(false);
            }); 
        });
    }
        
    private async _raspberryPrint(place, device, _html=null, _open=false){
        if (device == null){
            return false;
        }
        
        let _printed = false;
        
        // first we try against the configured WS server
        if (!_printed && (device.rasppi == place.till) && place.till.ws){
            _printed = await this._wsLocalSend(place, device, _html, _open);  
        }

        // next we try against the remote WS server
        if (!_printed){
            _printed = await this._wsRemoteSend(place, device, _html, _open);              
        }

        if (_printed && _open){
            this._OnDrawerNotify();
        }

        // finally we fallback to the server printing method
        if (!_printed){
            console.error("[PRINT] Cannot sent to printer (storing pdf backup on server)")

            let _getparams = {
                place: place.objid,
                printer: device.device,
            };
    
            if (_html){
                _getparams['height'] = await this.htmlHeight(_html);
            }
    
            if (_open){
                _getparams['drawer'] = true;
                if (!_html){
                    _html = 'drawer';
                }
            }
    
            _printed = await this._raspberrySend(_getparams, _html);               
        }

        return _printed;        
    }

    /*****************************/
    /* SERVICE METHODS           */
    /*****************************/

    async TicketToPdf(ticket, elem): Promise<Blob> {
        let _html = await this.htmlStyleContent(ticket.orderno, elem, 'assets/styles/ticket-print.css', null, false, [0, 0, 5, 0, 5], null, []);
        if (_html) {
            let _headers = {
                'Accept': 'text/html',
                'Content-Type': 'text/html'
            };
    
            let _url = AppConstants.baseURL + this._doExportUrl + "?width=80" + "&height=" + await this.htmlHeight(_html);
            let _request = this.http.post(_url, _html, _headers, 'blob');
            return new Promise((resolve, reject) => {
                let _subscription = _request.subscribe(
                data => {
                    let blob = new Blob([data as Blob], { type: 'application/pdf' });
                    resolve(blob);
                },
                error => {
                    if (error.status != 0) {
                        this.toast.ShowAlert('danger', this.lang.tr('@http_request_error', [error.status]));
                    } else {
                        console.error("[HTTP] status = 0");
                    }
                    reject(error);
                },
                () => {
                    _subscription.unsubscribe();
                });
            });
        } 
        else {
            throw new Error('HTML content is null');
        }
    }

    async PaymentToPdf(place, elem): Promise<Blob> {
        let _html = await this.htmlStyleContent(place.name, elem, 'assets/styles/ticket-print.css', null, false, [0, 0, 5, 0, 5], null, []);
        if (_html) {
            let _headers = {
                'Accept': 'text/html',
                'Content-Type': 'text/html'
            };
    
            let _url = AppConstants.baseURL + this._doExportUrl + "?width=80" + "&height=" + await this.htmlHeight(_html);
            let _request = this.http.post(_url, _html, _headers, 'blob');
            return new Promise((resolve, reject) => {
                let _subscription = _request.subscribe(
                data => {
                    let blob = new Blob([data as Blob], { type: 'application/pdf' });
                    resolve(blob);
                },
                error => {
                    if (error.status != 0) {
                        this.toast.ShowAlert('danger', this.lang.tr('@http_request_error', [error.status]));
                    } else {
                        console.error("[HTTP] status = 0");
                    }
                    reject(error);
                },
                () => {
                    _subscription.unsubscribe();
                });
            });
        } 
        else {
            throw new Error('HTML content is null');
        }
    }

    private _drawer_opening = {};
    private _AddDrawerOpening(device){
        if (device in this._drawer_opening){
            return false;
        }

        this._drawer_opening[device] = true;
        return true;    }

    private _DelDrawerOpening(device){
        if (device in this._drawer_opening){
            delete this._drawer_opening[device];
        }        
    }

    async OpenDrawer(source, place, device){
        if (!place.IsPOSDevice){
            return false;   // only POS devices can open drawer
        }

        // delete the printing action on next loop
        setTimeout(() => {
            this._DelDrawerOpening(device.objid);
        }, 0);

        if (this._AddDrawerOpening(device.objid)){
            await this._raspberryPrint(place, device, null, true);
            console.info("[PRINT] open drawer on device '" + device.device + "' (device id " + device.objid + ") from source '" + source + "'..");  
        }
    }

    private _AddPrinting(target, device, ticket){
        if (device in target){
            if (target[device].indexOf(ticket) != -1){
                return false;   // already being printed on this device
            }
        }
        else {
            target[device] = [];
        }

        target[device].push(ticket);
        return true;
    }

    private _DelPrinting(target, device, ticket){
        if (device in target){
            let _idx = target[device].indexOf(ticket);
            if (_idx != -1){
                target[device].splice(_idx, 1);
            }

            if (target[device].length == 0){
                delete target[device];
            }
        }
    }

    private _ticket_printing = {};
    private _AddTicketPrinting(device, ticket){
        return this._AddPrinting(this._ticket_printing, device, ticket);
    }

    private _DelTicketPrinting(device, ticket){
        this._DelPrinting(this._ticket_printing, device, ticket);
    }

    async SendTicket(source, ticket, device, elem, drawer = false) {
        // delete the printing action on next loop
        setTimeout(() => {
            this._DelTicketPrinting(device?.objid || 0, ticket);
        }, 0);

        if (this._AddTicketPrinting(device?.objid || 0, ticket)){
            console.info("[PRINT] ticket printing to device '" + (device?.device || '(null)') + "' (device id " + (device?.objid || 0) + ") from source '" + source + "'..");

            let _font = null;
            let _bold = false;
            let _margins = [ 0, 0, 0, 0, 0 ];
            let _style = null;

            if (device){
                _font = Math.max(device.font, 10);
                _bold = device.bold;
                _margins = [ device.width || 0, device.top || 0, device.right || 0, device.bottom || 0, device.left || 0 ];
                _style = device.style;
            }

            let _html = await this.htmlStyleContent(ticket.orderno, elem, 'assets/styles/ticket-print.css', _font, _bold, _margins, _style, []);
            if (_html){
                // first try to print through raspberry and else in traditional way
                if (await this._raspberryPrint(ticket.place, device, _html, drawer) == false){
                    if (!ticket.place.IsPOSDevice){
                        return;     // do not attempt local printing if not a pos device
                    }

                    if (this.platform.is('cordova')){
                        await this._cordovaPrintPage(ticket.orderno, _html);
                    }
                    else {
                        await this._browserPrintPage(ticket.orderno, _html);
                    }
                }
            }
        }
    }

    private _change_printing = {};
    private _AddChangePrinting(device, ticket){
        return this._AddPrinting(this._change_printing, device, ticket);
    }

    private _DelChangePrinting(device, ticket){
        this._DelPrinting(this._change_printing, device, ticket);
    }

    private async _processQueued(){
        let _now = new Date();
        let _printQueue = [];

        console.log("[PRINT]: connection restablished, process queued items")
        while (this._printQueue.length > 0){
            let _queued = this._printQueue.shift();
            if (_now.getTime() > _queued.expires){
                continue;   // this element is expired
            }

            if (this.http.IsOnline){
                await this._raspberryPrint(_queued.place, _queued.device, _queued.html, false);
            }
            else {      // back to queue
                _printQueue.push(_queued);
            }
        }

        this._printQueue = _printQueue;
    }

    async SendChange(source, ticket, device, elem) {
        // delete the printing action on next loop
        setTimeout(() => {
            this._DelChangePrinting(device.objid, ticket);
        }, 0);            

        if (this._AddChangePrinting(device.objid, ticket)){    
            console.info("[PRINT] ticket change printing to device '" + device.device + "' (device id " + device.objid + ") from source '" + source + "'..");

            let _font = null;
            let _bold = false;
            let _margins = [ 0, 0, 0, 0, 0 ];
            let _style = null;

            if (device){
                _font = Math.max(device.font, 10);
                _bold = device.bold;
                _margins = [ device.width || 0, device.top || 0, device.right || 0, device.bottom || 0, device.left || 0 ];
                _style = device.style;
            }

            let _html = await this.htmlStyleContent(ticket.orderno, elem, 'assets/styles/ticket-print.css', _font, _bold, _margins, _style, []);
            if (_html){
                let _localprint = !!ticket.place.till.ws;
                if (_localprint || this.http.IsOnline){
                    await this._raspberryPrint(ticket.place, device, _html, false);
                }
                else {  // offline: add to queque
                    let _expires = new Date();
                    _expires.setMinutes(_expires.getMinutes() + 5);

                    this._printQueue.push({
                        place: ticket.place,
                        device: device,
                        html: _html,
                        expires: _expires
                    });
                }
            }
        }
    }

    async PrintAudit(place, device, elem){
        console.info("[PRINT] cash audit printing to device '" +  (device?.device || '(null)')  + "'..");
        
        let _font = null;
        let _bold = false;
        let _margins = [ 0, 0, 0, 0, 0 ];
        let _style = null;

        if (device){
            _font = Math.max(device.font, 10);
            _bold = device.bold;
            _margins = [ device.width || 0, device.top || 0, device.right || 0, device.bottom || 0, device.left || 0 ];
            _style = device.style;
        }

        let _html = await this.htmlStyleContent(place.name, elem, 'assets/styles/ticket-print.css', _font, _bold, _margins, _style, []);
        if (_html){
            // first try to print through raspberry and else in traditional way            
            if (await this._raspberryPrint(place, device, _html, false) == false){
                if (this.platform.is('cordova')){
                    await this._cordovaPrintPage(place.name, _html);
                }
                else {
                    await this._browserPrintPage(place.name, _html);
                }
            }
        }
    }

    async PrintPayment(place, device, elem){
        console.info("[PRINT] account ticket printing to device '" + (device?.device || '(null)')  + "'..");
        
        let _font = null;
        let _bold = false;
        let _margins = [ 0, 0, 0, 0, 0 ];
        let _style = null;

        if (device){
            _font = Math.max(device.font, 10);
            _bold = device.bold;
            _margins = [ device.width || 0, device.top || 0, device.right || 0, device.bottom || 0, device.left || 0 ];
            _style = device.style;
        }
        
        let _html = await this.htmlStyleContent(place.name, elem, 'assets/styles/ticket-print.css', _font, _bold, _margins, _style, []);
        if (_html){
            // first try to print through raspberry and else in traditional way            
            if (await this._raspberryPrint(place, device, _html, false) == false){
                if (this.platform.is('cordova')){
                    await this._cordovaPrintPage(place.name, _html);
                }
                else {
                    await this._browserPrintPage(place.name, _html);
                }
            }
        }
    }

    async PrintReport(title, elem, style, links=[]){
        console.info("[PRINT] report printing..");
        
        let _html = await this.htmlStyleContent(title, elem, style, null, false, [0, 0, 0, 0, 0], null, links);
        if (_html){
            if (this.platform.is('cordova')){
                await this._cordovaPrintPage(title, _html);
            }
            else {
                await this._browserPrintPage(title, _html);
            }
        }
    }
}
