import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { Session } from './session';
import { CashChange } from './cashchange';
import { AuditInfo } from './auditinfo';
import { PlaceService } from './svcplace';

export interface _Audit {
    place: number;
    session: number;
    till: number; 
    cash: number;
};

interface _AuditData extends _Audit {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class AuditData extends DataObject {
    protected _audit: _AuditData = {
        place: null,
        session: null,
        till: null,
        cash: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._audit.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._audit.created;
    }

    get place(): Place{
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get session(): Session{
        return this._children['session'] || null;
    }

    set session(value: Session){
        if (this.SetChild('session', value, 'session')){
            this.ToUpdate = true;
        }
    }

    get cashchanges(): Array<CashChange> {
        return this._chldlist['cashchange'] || [];
    }

    get till(): PlaceService {
        return this._children['till'] || null;
    }

    set till(value: PlaceService) {
        if (this.SetChild('till', value, 'till')){
            this.ToUpdate = true;
        }
    }

    get cash(): number{
        return this._audit.cash;
    }

    set cash(value: number){
        if (this.patchValue(this._audit, 'cash', value)){
            this.ToUpdate = true;
        }
    }

    get auditinfo(): AuditInfo {
        let _auditinfos = this._chldlist['auditinfo'] || [];
        if (_auditinfos.length > 0){
            return _auditinfos[0]
        }
    
        return null;    // no audit information for this audit?
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change(){
        return {
            place: this._audit.place,
            session: this._audit.session,
            till: this._audit.till,
            cash: this._audit.cash
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'audits', by: 'place' } },  // this[by -> 'place'][to -> 'audits'] => this
            till: { item: this.till, relation_info: { to: null, by: 'till' } }          // no relation to this in this[by -> 'till']
        };
    }

    protected get Children() {
        let _children = [];

        if (this.auditinfo){
            _children.push(this.auditinfo);
        }

        for(let _item of this.cashchanges){
            _children.push(_item);
        }

        return _children;
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_audit: _Audit){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._audit, 'place', _audit['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'session', _audit['session']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'till', _audit['till']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'cash', _audit['cash']) || _toUpdate;

        return _toUpdate;
    }
    
    set Data(_audit: _Audit){
        this.patchValue(this._audit, 'created', _audit['created']);
        
        if (this._patchData(_audit)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._audit;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _AuditData {
        let _audit: _AuditData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            place: info['place'] ? parseInt(info['place']) : null,
            session: info['session'] ? parseInt(info['session']) : null,
            till: info['till'] ? parseInt(info['till']) : null,
            cash: info['cash'] ? parseFloat(info['cash']) : 0
        };
        return _audit;
    }

    private DoPatchValues(_audit: _Audit){
        this._patchData(_audit);

        if (_audit['session']){     // update children: 'session'
            let _objid = _audit['session'].toString();
            this.SetChild('session', new Session(_objid, this.data), 'session');
        }
        else {
            this.SetChild('session', null, 'session');
        }

        if (_audit['place']){     // update children: 'place'
            let _objid = _audit['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place');
        }  
        else {
            this.SetChild('place', null, 'place');
        }

        if (_audit['till']){     // update children: 'till'
            let _objid = _audit['till'].toString();
            this.SetChild('till', new PlaceService(_objid, this.data, this._objoptions), 'till');
        }  
        else {
            this.SetChild('till', null, 'till');
        }
    }

    protected _OnUpdate(info){
        let _audit = this._ddbb(info);
        this.patchValue(this._audit, 'objid', _audit['objid']);
        this.patchValue(this._audit, 'created', _audit['created']);
        this.DoPatchValues(_audit);

        if ('cashchange' in info) {  // update children: 'cashchange'
            this.SetChildren <CashChange> (info['cashchange'], 'cashchange', CashChange, 'audit');
        }

        if ('auditinfo' in info) {  // update children: 'auditinfo'
            this.SetChildren <AuditInfo> (info['auditinfo'], 'auditinfo', AuditInfo, 'audit');
        }        
    }
}

export class Audit extends AuditData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('AUDIT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Audit {
        return this._Copy(store) as Audit;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.IsLoaded;
    }

    get IsCommited(){
        return (this.ToInsert == false) && (this.objid != null);
    }

    ReloadInfo(){
        if (this.auditinfo == null){
            let _auditinfo = new AuditInfo(null, this.data);
            if (_auditinfo){
                _auditinfo.audit = this;
            }

            this.AddChild('auditinfo', _auditinfo, 'audit');
        }

        if (this.auditinfo != null){
            this.auditinfo.Reload();
        }

        this.auditinfo.DoRefresh('AUDITINFO');    
    }
}

